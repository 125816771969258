import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import config from '../../config';

function StripeWrapper({ stripeAccount, children }) {
  const [stripeObject, setStripeObject] = useState(null);

  const ELEMENTS_OPTIONS = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
      }
    ]
  };

  useEffect(() => {
    const fetchStripeObject = async () => {
      const stripeAccountObj = stripeAccount ? { stripeAccount } : {};
      const stripePromise = loadStripe(`${config.stripeKey}`, stripeAccountObj);

      setStripeObject(stripePromise);
    };

    fetchStripeObject();
  }, [stripeAccount]);

  if (!stripeObject) {
    return <p>Loading...</p>;
  }

  return (
    <Elements stripe={stripeObject} options={ELEMENTS_OPTIONS}>
      {children}
    </Elements>
  );
}

export default StripeWrapper;
