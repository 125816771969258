/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import config from '../../config';
import './PassengerProfile.scss';
import { useAuth0 } from '@auth0/auth0-react';
import imageOfJustin from '../../assets/images/justin.jpeg';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import colors from '../../assets/sass/colors';
import { AccessTokenContext } from '../../components/App';
import { useSelector } from 'react-redux';

const LANGUAGE = navigator.language.substring(0, 2);

const PassengerProfile = () => {
  const [passenger, setPassenger] = useState(null);
  const [initialPassenger, setInitialPassenger] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    hasDisability: null
  });
  const [initialPassengerType, setInitialPassengerType] = useState(null);
  const [newPassengerType, setNewPassengerType] = useState(null);
  const { user, logout } = useAuth0();
  const accessToken = useContext(AccessTokenContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const { t } = useTranslation('common');
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setPassenger((prevState) => {
      return {
        ...prevState,
        [id]: id === 'hasDisability' ? !passenger.hasDisability : value
      };
    });
  };

  const isDifferent = (
    initialPassenger,
    updatedPassenger,
    initialPassengerType,
    newPassengerType
  ) => {
    return !(
      initialPassenger.firstName === updatedPassenger.firstName &&
      initialPassenger.lastName === updatedPassenger.lastName &&
      initialPassenger.phoneNumber === updatedPassenger.phoneNumber &&
      initialPassenger.hasDisability === updatedPassenger.hasDisability &&
      initialPassengerType.id === newPassengerType.id
    );
  };

  const handleSave = async (event) => {
    try {
      if (!isDifferent(initialPassenger, passenger, initialPassengerType, newPassengerType))
        throw 'No changes were made, nothing to update.';

      const transitAgencyId = selectedTransitAgency.transit_agency_id;

      let reqBody = {
        first_name: passenger.firstName,
        last_name: passenger.lastName,
        phone: passenger.phoneNumber,
        hasDisability: passenger.hasDisability
      };

      let updatePassengerTypeBody = {
        passenger_type_id: newPassengerType.id
      };

      axios
        .put(`${config.blaiseApiUrl}/passengers/${user.sub}`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(() => {
          axios
            .put(
              `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transitAgencyId}/passengerType`,
              updatePassengerTypeBody,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then(() => {
              setInitialPassenger(passenger);
              setInitialPassengerType(newPassengerType);
              openSnackbar(t('profile.changesSaved'), colors.blaiseGreen);
            })
            .catch(() => {
              openSnackbar(t('profile.changesNotSaved'), colors.red);
            });
        })
        .catch(() => {
          openSnackbar(t('profile.changesNotSaved'), colors.red);
        });
    } catch (err) {
      console.log(err);
    }

    event.preventDefault();
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t('profile.deleteAccountCheck'))) {
      try {
        await axios.delete(`${config.blaiseApiUrl}/passengers/${user.sub}?auth0=true`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });

        location.reload(); // Reloading the page is all that is needed (since the user's account is already deleted, Auth0 can't log passenger out)
      } catch (err) {
        console.log('PassengerProfile::handleDelete', err);
        openSnackbar(t('profile.deleteAccountError'), colors.red);
      }
    }
  };

  const handlePassengerTypeChange = (event) => {
    const selectedPassengerType = taPassengerTypes.find(
      (element) => element.id === event.target.value
    );
    if (selectedPassengerType) {
      setNewPassengerType(selectedPassengerType);
    }
  };

  useEffect(() => {
    const transitAgencyId = selectedTransitAgency.transit_agency_id;

    (async () => {
      const passenger = (
        await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      setInitialPassenger(passenger);
      setPassenger(passenger);
    })();

    (async () => {
      const initialPassengerType = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transitAgencyId}/passengerType`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      setInitialPassengerType(initialPassengerType);
      setNewPassengerType(initialPassengerType);
    })();
  }, []);

  const labelPropsStyle = { shrink: true, style: { fontWeight: 'bold' } };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };
  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Box display="flex" flexDirection="column" alignItems="center" marginTop="150px">
        {passenger === null && taPassengerTypes === null && initialPassengerType === null ? (
          <div className="blaise-progress-top">
            <CircularProgress aria-label="Progress Icon" aria-busy="true" />
          </div>
        ) : null}
        {passenger ? (
          <form onSubmit={handleSave}>
            <fieldset>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{
                  overflow: 'auto'
                }}
              >
                <div className="form-group">
                  <TextField
                    label={t('profile.firstName')}
                    id="firstName"
                    type="text"
                    value={passenger.firstName || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.lastName')}
                    id="lastName"
                    type="text"
                    value={passenger.lastName || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.phoneNumber')}
                    id="phoneNumber"
                    type="tel"
                    value={passenger.phoneNumber || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.email')}
                    id="email"
                    type="text"
                    value={passenger.email}
                    onChange={handleChange}
                    disabled
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <InputLabel
                    id={t('passenger.select_passenger_type')}
                    style={{ fontSize: 15, fontWeight: 'bold', maxWidth: 'max-content' }}
                  >
                    {t('passenger.passenger_type')}
                  </InputLabel>
                  <div style={{ maxWidth: 'max-content' }}>
                    {newPassengerType ? (
                      <Select
                        onChange={handlePassengerTypeChange}
                        value={newPassengerType.id}
                        labelId={t('passenger.select_passenger_type')}
                        id="Select"
                      >
                        {taPassengerTypes.length > 0
                          ? taPassengerTypes.map((element) => {
                              return (
                                <MenuItem key={element.id} value={element.id}>
                                  {LANGUAGE === 'en' ? element.english : element.french}
                                </MenuItem>
                              );
                            })
                          : null}
                      </Select>
                    ) : null}
                  </div>
                </div>
                <div className="form-group">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={passenger.hasDisability}
                        onChange={handleChange}
                        name="hasDisability"
                        id="hasDisability"
                      />
                    }
                    label={t('profile.reducedMobility')}
                  />
                </div>
              </Box>
              <Box
                my={1}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Button type="submit" color="primary" variant="contained">
                  {t('profile.save')}
                </Button>
                <Button type="button" color="secondary" variant="contained" onClick={handleDelete}>
                  {t('profile.deleteAccount')}
                </Button>
              </Box>
              <br />
            </fieldset>
          </form>
        ) : null}
      </Box>
    </>
  );
};

export default PassengerProfile;
