import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { faChevronLeft, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../assets/sass/colors';
import './PassengersAndPayment.scss';
import AddPassenger from '../AddPassenger/AddPassenger';
import Wallet from '../../Wallet/Wallet';
import { PassengerAndPaymentView } from '../../../enums/passengerAndPaymentViews';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import config from '../../../config';
import { useAuth0 } from '@auth0/auth0-react';
import { PaymentType } from '../../../enums/PaymentType';
import { AccessTokenContext } from '../../App';

const LANGUAGE = navigator.language.substring(0, 2);

// TODO: Deconstruct all  Do not ...
const PassengersAndPayment = ({
  previousStep,
  openSnackbar,
  currentPassengerAndPaymentView,
  setCurrentPassengerAndPaymentView,
  validateForm,
  isTripRequestSubmitting
}) => {
  const [accommodationTypes, setAccommodationTypes] = useState([]);
  const { t } = useTranslation('common');
  const { user } = useAuth0();
  const accessToken = useContext(AccessTokenContext);
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const originCoords = useSelector((state) => state.originCoords);
  const destCoords = useSelector((state) => state.destCoords);
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const passengers = useSelector((state) => state.passengers);
  const priceObject = useSelector((state) => state.priceObject);
  const originAddress = useSelector((state) => state.originAddress);
  const destAddress = useSelector((state) => state.destAddress);
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTransitAgency?.transit_agency_id) {
      getPassengerDetails();
    }
  }, [selectedTransitAgency]);

  useEffect(() => {
    if (paymentMethod && originCoords && destCoords) {
      calculateTripPrice(paymentMethod);
    }
  }, [passengers, originCoords, destCoords, paymentMethod]);

  const getPassengerDetails = async () => {
    const { transit_agency_id } = selectedTransitAgency;

    // Get Accommodation Types
    const accommodationTypesRaw = (
      await axios.get(`${config.blaiseApiUrl}/allTransitAgencies/accomodations`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    ).data;

    const accommodationTypes = accommodationTypesRaw.map((accommodationType) => {
      return { ...accommodationType, selected: false };
    });

    setAccommodationTypes(accommodationTypes);

    // Set Global Passengers State If Null
    if (passengers === null || Object.keys(passengers).length === 0) {
      const userPassengerType = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transit_agency_id}/passengerType`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      let passengerObj = {};

      for (const passengerType of taPassengerTypes) {
        passengerObj[passengerType.type] = [];
      }

      try {
        passengerObj[userPassengerType.type]?.push({
          accommodationTypes,
          isExpanded: true,
          type: userPassengerType.type
        });

        dispatch({ type: 'SET_PASSENGERS', payload: passengerObj });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const calculateTripPrice = async (paymentMethod) => {
    try {
      const departTrip = {
        departOriginLat: originCoords.lat,
        departOriginLon: originCoords.lng,
        departDestLat: destCoords.lat,
        departDestLon: destCoords.lng
      };

      const { id } = paymentMethod; // Only send payment method ID, otherwise more sensitive information is sent than necessary.

      const request = {
        origin_address: [originCoords.lat, originCoords.lng],
        destination_address: [destCoords.lat, destCoords.lng],
        transitAgencyId: selectedTransitAgency.transit_agency_id,
        payment_type: id,
        demand: passengers,
        bundleAddresses: [departTrip]
      };

      const priceObject = (
        await axios.post(`${config.blaiseApiUrl}/pricing/calculateTripPrice`, request, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      ).data;

      dispatch({ type: 'SET_PRICE_OBJECT', payload: priceObject });
    } catch (error) {
      throw new Error(error);
    }
  };

  const getPassengerCount = (format) => {
    let count = 0;

    if (passengers) {
      Object.values(passengers).map((passengerType) => {
        count += passengerType.length;
      });
    }

    if (format) {
      return count === 1 ? `1 ${t('tripReq.passenger')}` : `${count} ${t('tripReq.passengers')}`;
    }

    return count;
  };

  const getPaymentMethodText = () => {
    // If a payment method is selected -> return its label
    if (paymentMethod?.id || paymentMethod?.type) {
      if (paymentMethod.card) {
        return `${
          paymentMethod.card.brand.charAt(0).toUpperCase() + paymentMethod.card.brand.slice(1)
        } ****${paymentMethod.card.last4}`;
      }

      return paymentMethod.translation;
    }

    return t('payment.select_payment_method');
  };

  const translatePassengerType = (passengerType) => {
    const passType = taPassengerTypes.find((element) => element.type === passengerType);
    return LANGUAGE === 'en' ? passType?.english : passType?.french;
  };

  const tripTotal =
    paymentMethod?.type === PaymentType.PASS ? (
      <>{t('tripReq.free')}</>
    ) : (
      <>${(priceObject?.price / 100).toFixed(2)}</>
    );

  return (
    <>
      {/* Default view */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.DEFAULT ? (
        <Box className="passenger-and-payment-container">
          <Box className="mobile-scroll-container">
            <Box className="top-container">
              {/* Top nav */}
              <Button
                disabled={isTripRequestSubmitting}
                onClick={previousStep}
                style={{ marginBottom: '1rem', fontWeight: '500' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '0.75rem' }}
                  color={colors.gray800}
                  icon={faChevronLeft}
                  size="1x"
                />
                {t('labels_reusable.back')}
              </Button>

              {/* Passenger */}
              <Button
                onClick={() =>
                  setCurrentPassengerAndPaymentView(PassengerAndPaymentView.ADD_PASSENGERS)
                }
                disabled={isTripRequestSubmitting}
                className="passengers-payment-button"
              >
                <Typography>{getPassengerCount(true)}</Typography>
                <FontAwesomeIcon
                  style={{ marginRight: '12px' }}
                  color={colors.gray800}
                  icon={faPlus}
                  size="1x"
                />
              </Button>

              {/* Payment method */}
              <Button
                onClick={() =>
                  setCurrentPassengerAndPaymentView(PassengerAndPaymentView.PAYMENT_METHOD)
                }
                disabled={isTripRequestSubmitting}
                className="passengers-payment-button"
              >
                <Typography>{getPaymentMethodText()}</Typography>
                <FontAwesomeIcon
                  style={{ marginRight: '12px' }}
                  color={colors.gray800}
                  icon={faChevronRight}
                  size="1x"
                />
              </Button>
            </Box>

            {/* Summary */}
            {getPassengerCount() > 0 ? (
              <Box className="summary-container">
                <Typography className="summary-header">{t('payment.summary')}</Typography>
                <Box className="passengers-container">
                  {passengers
                    ? Object.keys(passengers).map((passengerType, idx) => {
                        const passengerTypeCount = passengers[passengerType].length;

                        if (passengerTypeCount > 0) {
                          return (
                            <Box key={idx} className="summary-item">
                              <Typography>{translatePassengerType(passengerType)}</Typography>
                              <Typography>{`${passengerTypeCount}x`}</Typography>
                            </Box>
                          );
                        }
                      })
                    : null}
                </Box>
                <hr />
                {paymentMethod?.type ? (
                  <Box className="summary-item">
                    <Typography className="total">{t('payment.total')}</Typography>
                    <Typography className="total">{tripTotal}</Typography>
                  </Box>
                ) : null}
                <Typography className="no-show-penalty">
                  {t('tripReq.no_show_penalties_may_apply')}
                </Typography>
              </Box>
            ) : null}
          </Box>

          <Box className="next-button">
            <Button
              onClick={validateForm}
              disabled={
                !originAddress ||
                !destAddress ||
                !paymentMethod?.type || // There is an origin, destination and payment method selected
                (paymentMethod?.id === PaymentType.TICKETS &&
                  priceObject?.price / 100 > paymentMethod?.passengerBalance) || // Passenger has enough ticket balance to pay for trip
                isTripRequestSubmitting // Trip request is submitting
              }
              color="primary"
              variant="contained"
            >
              {paymentMethod?.id === PaymentType.TICKETS &&
              priceObject?.price / 100 > paymentMethod?.passengerBalance ? (
                <>{t('console.insufficientFundsError')}</>
              ) : (
                <>{t('labels_reusable.request')} </>
              )}
            </Button>
          </Box>
        </Box>
      ) : null}

      {/* Add passengers */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.ADD_PASSENGERS ? (
        <AddPassenger
          setCurrentPassengerAndPaymentView={setCurrentPassengerAndPaymentView}
          accommodationTypes={accommodationTypes}
          translatePassengerType={translatePassengerType}
          openSnackbar={openSnackbar}
        />
      ) : null}

      {/* Payment method */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.PAYMENT_METHOD ? (
        <Wallet
          viewFrom="tripRequest"
          setCurrentPassengerAndPaymentView={setCurrentPassengerAndPaymentView}
        />
      ) : null}
    </>
  );
};

export default PassengersAndPayment;
