import { createStore, applyMiddleware } from 'redux';
import { reducers } from './Reducers';
import createSagaMiddleware from 'redux-saga';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    console.log(err);
  }
}
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
const sagaMiddleware = createSagaMiddleware();

const persistedState = loadFromLocalStorage();

const middleware = [sagaMiddleware];

const store = createStore(reducers, persistedState, applyMiddleware(...middleware));

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
