import React, { useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './Sidebar.scss';
import { useSelector } from 'react-redux';
import ExpandedSidebar from './ExpandedSidebar';
import { useAuth0 } from '@auth0/auth0-react';
import MinimizedSidebar from './MinimizedSidebar';

const Sidebar = ({
  setAccessToken,
  setIsMobileDrawerOpen,
  operatorSupportLink,
  isLoadingOperatorList,
  checkIfSecretAccessIsAllowed,
  operatorList,
  selectedOperator,
  setOperator,
  setSelectedOperator
}) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [open, setOpen] = useState(false);
  const sidebarRef = useRef();
  const helpRef = useRef();
  const aboutUsRef = useRef();
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    setAccessToken(null);
    localStorage.removeItem('state');
    logout({ returnTo: window.location.origin });
  };

  const handleCollapse = () => {
    setSidebarExpanded((prevState) => !prevState);
  };

  const handleExpandSidebarOperatorSelection = () => {
    setSidebarExpanded(true);
    setTimeout(() => {
      setOpen(true);
    }, 500);
  };

  const handleExpandAccordion = (ref) => {
    setSidebarExpanded(true);
    setTimeout(() => {
      ref.current.click();
    }, 200); // Required because ExpandedSidebar hasn't been rendered yet.
  };

  return (
    <aside ref={sidebarRef} className={`sidebarWrapper ${!sidebarExpanded ? 'collapsed' : ''}`}>
      {sidebarExpanded ? (
        <ExpandedSidebar
          handleCollapse={handleCollapse}
          handleLogout={handleLogout}
          operatorSupportLink={operatorSupportLink}
          user={user}
          helpRef={helpRef}
          aboutUsRef={aboutUsRef}
          open={open}
          setOpen={setOpen}
          checkIfSecretAccessIsAllowed={checkIfSecretAccessIsAllowed}
          operatorList={operatorList}
          isLoadingOperatorList={isLoadingOperatorList}
          selectedOperator={selectedOperator}
          setOperator={setOperator}
          setIsMobileDrawerOpen={setIsMobileDrawerOpen}
          setSelectedOperator={setSelectedOperator}
        />
      ) : (
        <MinimizedSidebar
          handleCollapse={handleCollapse}
          handleLogout={handleLogout}
          handleExpandSidebarOperatorSelection={handleExpandSidebarOperatorSelection}
          handleExpandAccordion={handleExpandAccordion}
          helpRef={helpRef}
          aboutUsRef={aboutUsRef}
          isLoadingOperatorList={isLoadingOperatorList}
          selectedOperator={selectedOperator}
          operatorList={operatorList}
          setSidebarExpanded={setSidebarExpanded}
        />
      )}
    </aside>
  );
};

export default withRouter(Sidebar);
