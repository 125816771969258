const ROUTES = {
  home: '/',
  trips: '/viewRide',
  history: '/viewPastRide',
  profile: '/passengerProfile',
  wallet: '/wallet',
  tripRequest: '/tripRequest',
  tripDetails: '/tripDetails',
  tripRating: '/tripRating',
  tripProcess: '/tripProcess',
  downloadTheApp: '/downloadTheApp'
};

export default ROUTES;
