import { createMuiTheme } from '@material-ui/core/styles';
import colors from '../../assets/sass/colors';

const theme = createMuiTheme({
  palette: {
    primary: { main: colors.blaiseGreen, contrastText: colors.black },
    secondary: { main: colors.red, contrastText: colors.black }
  },
  typography: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontSize: 14
  },
  popover: {
    pointerEvents: 'none'
  },
  overrides: {
    MuiButton: {
      root: {
        margin: '5px',
        padding: '5px 10px',
        textTransform: 'capitalize'
      }
    }
  }
});

export default theme;
