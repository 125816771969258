import React, { useState } from 'react';
import { differenceInDays } from 'date-fns';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCheck, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';

import { PaymentType } from '../../enums/PaymentType';
import { dateFormat } from '../../utils/dateTimeFormats';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const PassPaymentMethodItem = ({
  isSelected,
  text,
  passDetails,
  historical,
  viewFromTripRequest
}) => {
  const [viewPassDetails, setViewPassDetails] = useState(false);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const selectedItem = {
    id: '',
    type: PaymentType.PASS,
    translation: text,
    price_id: passDetails.price_stripe_id
  };

  // default for expired passes
  let daysLeft = 0;
  const start = new Date(passDetails.current_period_start * 1000);
  const end = new Date(passDetails.current_period_end * 1000);

  if (passDetails.status !== 'canceled') {
    daysLeft = differenceInDays(end, new Date());
  }

  return (
    <Box>
      <Box display="flex" direction="row" justifyContent="space-between">
        {historical ? (
          <Box style={styles.itemContainer}>
            <Grid container display="flex" direction="row" alignItems="center">
              <Grid item style={styles.iconContainer}>
                <FontAwesomeIcon icon={faTicketAlt} color={colors.black} size="2x" />
              </Grid>
              <Grid style={styles.title}>{passDetails.name}</Grid>
            </Grid>
          </Box>
        ) : (
          <Button
            onClick={() => dispatch({ type: 'SET_PAYMENT_METHOD', payload: selectedItem })}
            style={styles.itemContainer}
          >
            <Box container display="flex" direction="row" alignItems="center">
              <Box item style={styles.iconContainer}>
                <FontAwesomeIcon icon={faTicketAlt} color={colors.black} size="2x" />
              </Box>
              <Box
                style={viewFromTripRequest ? styles.tripRequestTitle : styles.title}
                title={passDetails.name}
              >
                {passDetails.name}
              </Box>
              <Box style={{ width: '20px' }}>
                {isSelected && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={colors.blaiseGreen}
                    size="lg"
                    aria-hidden="true"
                    title={`${text} selected`}
                  />
                )}
              </Box>
            </Box>
          </Button>
        )}

        <Button style={styles.viewButton} onClick={() => setViewPassDetails(!viewPassDetails)}>
          {viewPassDetails ? (
            <FontAwesomeIcon icon={faAngleUp} color={colors.black} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} color={colors.black} size="lg" />
          )}
        </Button>
      </Box>

      {viewPassDetails && (
        <Box display="flex" flexDirection="column" mx={3} mb={1}>
          <Typography variant="body2">
            {t('transitPasses.days_left_pass')} {daysLeft}
          </Typography>
          <Typography variant="body2">
            {t('transitPasses.purchase_date')} {dateFormat(start)}
          </Typography>
          <Typography variant="body2">
            {t('transitPasses.expiry_date')} {dateFormat(end)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PassPaymentMethodItem;

const styles = {
  iconContainer: {
    width: '3em'
  },
  itemContainer: {
    padding: '0.5em',
    margin: '0.5em'
  },
  title: {
    paddingLeft: 10,
    paddingRight: 30
  },
  tripRequestTitle: {
    paddingLeft: 10,
    paddingRight: 30,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '240px'
  },
  viewButton: {
    backgroundColor: 'transparent',
    minWidth: '32px',
    height: '32px',
    alignSelf: 'center'
  }
};
