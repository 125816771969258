import Firebase from 'firebase';
import config from '../config';
import store from './redux/Store';

export const createMessageListeners = async () => {
  // init app
  const dbConfig = {
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    databaseURL: config.firebaseDatabaseUrl,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingId,
    appId: config.firebaseAppId,
    measurementId: config.firebaseMeasurementId
  };

  try {
    Firebase.initializeApp(dbConfig);
  } catch (err) {
    console.log(err);
  }

  //For when the app is in the foreground, we also don't have to add code for showiung local messages bc the background client takes care of that
  Firebase.messaging().onMessage(async (remoteMessage) => {
    _handleNotification(remoteMessage);
  });
};

export const initFirebaseAppDB = async () => {
  const dbConfig = {
    apiKey: config.firebaseApiKey,
    authDomain: config.firebaseAuthDomain,
    databaseURL: config.firebaseDatabaseUrl,
    projectId: config.firebaseProjectId,
    storageBucket: config.firebaseStorageBucket,
    messagingSenderId: config.firebaseMessagingId,
    appId: config.firebaseAppId,
    measurementId: config.firebaseMeasurementId
  };

  try {
    Firebase.initializeApp(dbConfig);
  } catch (err) {
    console.log(err);
  }
};

export function _handleNotification(remoteMessage) {
  let type = remoteMessage.data.type;

  // i'm sending these all to the same place and doing the rest of the notifications later in another pr, this one's just getting too huge
  switch (type) {
    case 'trip_updated':
      break;
    case 'trip_details':
      break;
    case 'trip_details_update':
      break;
    case 'unlocked_trip_details_update':
      break;
    case 'update_route_shape':
      _handleUpdateRouteShape();
      break;
    case 'get_off_at_next_stop':
      break;
    default:
      break;
  }
}

const _handleUpdateRouteShape = async () => {
  store.dispatch({ type: 'SET_RELOAD_LOCKED_ROUTE_SHAPE', payload: true });
};
