import React, { useEffect, useState } from 'react';
import { Box, Divider, Modal, MenuItem, Select, Typography } from '@material-ui/core';
import PassPaymentMethodItem from './PassPaymentMethodItem';
import { useTranslation } from 'react-i18next';

const PassHistoryModal = ({ open, handleClose, expiredPasses }) => {
  const [yearToShow, setYearToShow] = useState(new Date().getFullYear());
  const [passesForYear, setPassesForYear] = useState([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    filterPassesForYear();
  }, [yearToShow]);

  const now = new Date();
  const yearOptions = Array.from({ length: now.getFullYear() + 1 - 2022 }, (_, i) => {
    const year = i + 2022;
    return { label: year.toString(), value: year };
  });

  const filterPassesForYear = () => {
    const filtered = expiredPasses?.filter((pass) => {
      const date = new Date(pass.current_period_start * 1000);
      const year = date.getFullYear();
      return year === yearToShow;
    });

    const sorted = filtered?.sort((a, b) => b.current_period_start - a.current_period_start);
    setPassesForYear(sorted);
  };

  const handleChange = (event) => {
    const { target: value } = event;
    setYearToShow(value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="paper" style={styles.modalContainer}>
        <Typography variant="h6">{t('transitPasses.expired_pass')}</Typography>
        <Box display="flex" alignItems="center" justifyContent="center" py={2}>
          {yearOptions && (
            <Select value={yearToShow} onChange={handleChange}>
              {yearOptions?.map((option) => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </Box>
        {passesForYear.length > 0 && (
          <Box>
            {passesForYear.map((pass) => {
              return (
                <Box key={pass.price_stripe_id}>
                  <PassPaymentMethodItem passDetails={pass} text={pass.name} historical={true} />
                  <Divider light />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PassHistoryModal;

const styles = {
  modalContainer: {
    overflowY: 'auto',
    maxHeight: '90%',
    minWidth: '40%'
  }
};
