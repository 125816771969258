import React, { useState, useEffect, useContext } from 'react';
import config from '../../config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { withRouter } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { isAndroid, isMobile, isTablet } from 'react-device-detect';
import { Box, Button, CircularProgress, Grid, Typography, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import colors from '../../assets/sass/colors';
import { PaymentType } from '../../enums/PaymentType';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import PaymentMethodItem from '../PaymentMethodItem/PaymentMethodItem';
import PassPaymentMethodItem from '../Pass/PassPaymentMethodItem';
import PassHistoryModal from '../Pass/PassHistoryModal';
import AddPhysicalTicketModal from '../AddPaymentModals/AddPhysicalTicketModal';
import AddCardPaymentModal from '../AddPaymentModals/AddCardPaymentModal';
import PurchasePassModal from '../AddPaymentModals/PurchasePassModal';
import { PassengerAndPaymentView } from '../../enums/passengerAndPaymentViews';
import './Wallet.scss';
import { AccessTokenContext } from '../App';
import { getClientSecret } from '../../utils/stripe';
import { useTranslation } from 'react-i18next';

const LANGUAGE = navigator.language.substring(0, 2);

const Wallet = ({ viewFrom, setCurrentPassengerAndPaymentView, location }) => {
  const dispatch = useDispatch();
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const defaultPaymentMethod = useSelector((state) => state.defaultPaymentMethod);
  const selectedOperator = location.state
    ? location.state.selectedOperator
    : selectedTransitAgency
    ? selectedTransitAgency
    : null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [billingDetails, setBillingDetails] = useState({ email: '', phone: '', name: '' });
  const [passengerBalance, setPassengerBalance] = useState(0);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [currentPaymentMethods, setCurrentPaymentMethods] = useState([]);
  const [physicalTicketModalVisible, setPhysicalTicketModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [purchasePassModalVisible, setPurchasePassModalVisible] = useState(false);
  const [operatorPaymentTypes, setOperatorPaymentTypes] = useState([]);
  const [transitPasses, setTransitPasses] = useState([]);
  const [activePasses, setActivePasses] = useState([]);
  const [expiredPasses, setExpiredPasses] = useState([]);
  const [passHistoryModalVisible, setPassHistoryModalVisible] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const accessToken = useContext(AccessTokenContext);
  const { user } = useAuth0();
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (selectedOperator?.transit_agency_id) {
      retrieveStripeCustomerId();
      getTAPaymentTypes();
      getPassengerBalance();
      retrieveDefaultPM();
    }
  }, []);

  useEffect(() => {
    if (defaultPaymentMethod) {
      dispatch({ type: 'SET_PAYMENT_METHOD', payload: defaultPaymentMethod });
    }
  }, [defaultPaymentMethod]);

  useEffect(() => {
    if (operatorPaymentTypes.some((type) => type.payment_type === PaymentType.PASS)) {
      retrieveTransitAgencyPasses();
      retrievePassengerPass();
    }
  }, [operatorPaymentTypes]);

  const getTAPaymentTypes = async () => {
    try {
      setLoading(true);
      const paymentTypes = (
        await axios.get(
          `${config.blaiseApiUrl}/transitagencies/${selectedOperator.transit_agency_id}/paymentTypes`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      setOperatorPaymentTypes(paymentTypes);
    } catch (error) {
      throw new Error(error);
    } finally {
      setLoading(false);
    }
  };

  const getPassengerBalance = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${config.blaiseApiUrl}/passengers/${user.sub}/getPassengerBalance/${selectedOperator.transit_agency_id}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setPassengerBalance(response.data.balance);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const retrieveStripeCustomerId = async () => {
    try {
      setLoading(true);
      const response = (
        await axios.post(
          `${config.blaiseApiUrl}/payment/retrieveCustomerId_v2`,
          { passenger_id: user.sub, transitAgencyId: selectedOperator.transit_agency_id },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      if (response.connected_customer_id) {
        setStripeCustomerId(response.connected_customer_id);
        showCustomerPaymentMethods(response.connected_customer_id);
        retrieveDefaultPM(response.connected_customer_id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const retrieveDefaultPM = async (customerId = null) => {
    try {
      setLoading(true);
      const response = (
        await axios.post(
          `${config.blaiseApiUrl}/payment/retrieveDefaultPM_v2`,
          {
            customer_id: customerId,
            transit_agency_id: selectedOperator.transit_agency_id,
            blaise_passenger_id: user.sub
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;
      let result = response;
      // Format the response to match selectedItem object if it is not a card
      if (response.type !== 'card') {
        result = {
          id: response.id,
          type: response.type,
          translation: LANGUAGE === 'en' ? response.english : response.french
        };
      }
      dispatch({ type: 'SET_DEFAULT_PAYMENT_METHOD', payload: result });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateDefaultPaymentMethod = async () => {
    try {
      await axios.post(
        `${config.blaiseApiUrl}/payment/updateCustomerDefaultPM_v2`,
        {
          ...paymentMethod,
          blaise_passenger_id: user.sub,
          transit_agency_id: selectedOperator.transit_agency_id,
          customer: stripeCustomerId
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );

      openSnackbar(t('profile.changesSaved'), colors.blaiseGreen);
    } catch (error) {
      console.log(error);
    }
  };

  const savePayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    try {
      const passenger = (
        await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      const clientSecret = await getClientSecret({
        passenger_id: user.sub,
        transit_agency_id: selectedOperator.transit_agency_id,
        email: passenger.email,
        first_name: passenger.firstName,
        last_name: passenger.lastName,
        phone_number: passenger.phoneNumber,
        accessToken
      });

      const setupIntent = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });

      if (setupIntent.error) {
        setError(setupIntent.error);
        openSnackbar(t('console.genericError'), colors.red);
      } else {
        // If card was successfully added, then fetch new list of payment methods.
        showCustomerPaymentMethods(stripeCustomerId);
      }
    } catch (err) {
      openSnackbar(t('console.recheck_card'), colors.red);
    }

    setPaymentModalVisible(false);
  };

  const showCustomerPaymentMethods = async (customer_id = stripeCustomerId) => {
    if (customer_id !== null) {
      try {
        setLoading(true);
        const response = await axios.post(
          `${config.blaiseApiUrl}/payment/showCustomerPaymentMethods_v2`,
          { customer_id: customer_id, transitAgencyId: selectedOperator.transit_agency_id },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        );

        setCurrentPaymentMethods(response.data.data);
      } catch (error) {
        throw new Error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const retrieveTransitAgencyPasses = async () => {
    try {
      setLoading(true);
      // get current passenger type
      const currentPassengerType = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${selectedOperator.transit_agency_id}/passengerType`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data.type;

      // get TA passes
      const availablePasses = (
        await axios.get(
          `${config.blaiseApiUrl}/transitagencies/${selectedOperator.transit_agency_id}/passes?isConnectedAccount=true`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      const passes = {};
      passes[currentPassengerType] = new Array();

      // filter the passes based on passenger type
      for (const pass of availablePasses) {
        if (pass.passenger_type === currentPassengerType) {
          passes[currentPassengerType].push(pass);
        } else if (!pass.passenger_type) {
          // this case occurs when TAs have passes that do not care about passenger type
          passes['anyone'] = pass;
        }
      }

      const filteredPass = passes[currentPassengerType]
        ? passes[currentPassengerType]
        : [passes['anyone']];

      // this checks to make sure that there is a pass available for the passenger
      if (filteredPass[0]) {
        setTransitPasses(filteredPass);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const retrievePassengerPass = async () => {
    try {
      setLoading(true);

      const response = (
        await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}/passes`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      const current = response.filter(
        (pass) => pass.status === 'active' || pass.status === 'trialing'
      );
      setActivePasses(current);

      const expired = response.filter((pass) => pass.status === 'canceled');
      setExpiredPasses(expired);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  const handleDetachCard = async (card) => {
    try {
      await axios.post(
        `${config.blaiseApiUrl}/payment/removeCardFromCustomer_v2`,
        {
          paymentMethodId: card,
          passengerId: user.sub,
          transitAgencyId: selectedOperator.transit_agency_id
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      );

      showCustomerPaymentMethods();
      openSnackbar(t('labels_reusable.success'), colors.blaiseGreen);
    } catch (error) {
      // TODO spanish trans
      console.log('WhereAndWhen::handleDetachCard', error);
      const messageLang = `message_${LANGUAGE}`;
      openSnackbar(error.response?.data[messageLang], colors.red);
    }
  };

  const showPurchasePassSuccess = () => {
    openSnackbar(t('labels_reusable.success'), colors.blaiseGreen);
  };

  // Check what payment types the transit agency allows
  const cardType = operatorPaymentTypes.find((type) => type.payment_type === PaymentType.CARD);

  const ticketType = operatorPaymentTypes.find((type) => type.payment_type === PaymentType.TICKETS);

  const passType = operatorPaymentTypes.find((type) => type.payment_type === PaymentType.PASS);

  const additionalPaymentTypes = operatorPaymentTypes.filter(
    (type) =>
      type.payment_type !== PaymentType.CARD &&
      type.payment_type !== PaymentType.TICKETS &&
      type.payment_type !== PaymentType.PASS
  );

  // When viewing wallet from the trip request page:
  const tripRequestView = () => {
    return (
      <Box className="trip-request-wallet-container">
        {/* Header */}
        <Box className="header-container">
          <Typography className="header">{t('payment.payment_method')}</Typography>
          <Button
            onClick={() => setCurrentPassengerAndPaymentView(PassengerAndPaymentView.DEFAULT)}
          >
            <FontAwesomeIcon
              color={colors.gray600}
              icon={faTimes}
              style={{ height: '16px', width: '16px' }}
            />
          </Button>
        </Box>
        <Box className="body-content">
          <Box style={{ width: '100%' }}>
            {passType && (
              <Box style={{ width: '100%' }}>
                {activePasses.length > 0 &&
                  activePasses.map((pass, index) => {
                    return (
                      <PassPaymentMethodItem
                        key={pass.price_stripe_id}
                        paymentItem={passType}
                        isSelected={
                          pass.price_stripe_id === paymentMethod?.price_id ||
                          (paymentMethod?.id === PaymentType.PASS && index === 0)
                        }
                        text={
                          LANGUAGE === 'en'
                            ? passType.translation.english
                            : passType.translation.french
                        }
                        passDetails={pass}
                        viewFromTripRequest={true}
                      />
                    );
                  })}
              </Box>
            )}
            {cardType &&
              currentPaymentMethods.map((card) => {
                return (
                  <>
                    <PaymentMethodItem
                      key={card.id}
                      paymentItem={cardType}
                      isSelected={card.id === paymentMethod?.id}
                      text={`${
                        card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)
                      } ****${card.card.last4}`}
                      cardDetails={card}
                      showExpiryDate={true}
                      viewFromTripRequest={true}
                    />
                  </>
                );
              })}
            {ticketType && (
              <Box style={{ width: '100%' }}>
                <PaymentMethodItem
                  key={ticketType.payment_type}
                  paymentItem={ticketType}
                  isSelected={ticketType.payment_type === paymentMethod?.type}
                  passengerBalance={passengerBalance}
                  text={
                    LANGUAGE === 'en'
                      ? `${ticketType.translation.english}  $${(passengerBalance / 100).toFixed(2)}`
                      : `${ticketType.translation.french}  $${(passengerBalance / 100).toFixed(2)}`
                  }
                  viewFromTripRequest={true}
                />
              </Box>
            )}
            {additionalPaymentTypes && (
              <>
                {additionalPaymentTypes.map((type) => {
                  return (
                    <>
                      <PaymentMethodItem
                        key={type.payment_type}
                        paymentItem={type}
                        isSelected={type.payment_type === paymentMethod?.type}
                        text={
                          LANGUAGE === 'en' ? type.translation.english : type.translation.french
                        }
                        viewFromTripRequest={true}
                      />
                    </>
                  );
                })}
              </>
            )}
          </Box>
          <Box className="additional-payment-methods">
            <Typography style={styles.paymentTitle}>
              {t('payment.additional_payment_method')}
            </Typography>
            <Box my={1.5} display="flex" flexDirection="column" alignItems="flex-start">
              {transitPasses && activePasses.length === 0 && (
                <Button
                  style={styles.addMethodOption}
                  onClick={() => setPurchasePassModalVisible(true)}
                >
                  {t('transitPasses.purchase_pass')}
                </Button>
              )}

              {cardType && (
                <Button style={styles.addMethodOption} onClick={() => setPaymentModalVisible(true)}>
                  {t('payment.add_new_card')}
                </Button>
              )}
              {ticketType && (
                <Button
                  style={styles.addMethodOption}
                  onClick={() => setPhysicalTicketModalVisible(true)}
                >
                  {t('payment.add_physical_ticket_number')}
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <Box
          className="next-button"
          style={{
            paddingBottom: `${(isMobile || isTablet) && isAndroid && '76px'}` // Add 56px (onto the default 20) padding for Android's bottom nav
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setCurrentPassengerAndPaymentView(PassengerAndPaymentView.DEFAULT)}
          >
            {t('profile.save')}
          </Button>
        </Box>
      </Box>
    );
  };

  // When viewing wallet from the side bar navigation:
  const pageView = () => {
    return (
      <>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <CircularProgress aria-label="Progress Icon" aria-busy="true" />
          </Box>
        ) : (
          <Box>
            <Box
              px={4}
              py={2}
              style={{
                height: `${isMobile && `calc(${window.innerHeight}px - 48px - 86px)`}`, // 48px for navbar, 86px for save button.
                overflow: 'auto'
              }}
            >
              {passType && (
                <Box mb={2}>
                  <Typography style={styles.paymentTitle}>{t('transitPasses.pass')}</Typography>
                  {activePasses.length > 0 &&
                    activePasses.map((pass, index) => {
                      return (
                        <PassPaymentMethodItem
                          key={pass.price_stripe_id}
                          paymentItem={passType}
                          isSelected={
                            pass.price_stripe_id === paymentMethod?.price_id ||
                            (paymentMethod.type === PaymentType.PASS && index === 0)
                          }
                          text={pass.name}
                          passDetails={pass}
                        />
                      );
                    })}

                  {transitPasses && activePasses.length === 0 && (
                    <Box>
                      <Divider light />
                      <Button
                        style={styles.addMethodButton}
                        onClick={() => setPurchasePassModalVisible(true)}
                      >
                        <Grid container direction="row" alignItems="center">
                          <Grid item style={styles.iconContainer}>
                            <FontAwesomeIcon icon={faPlus} color={colors.black} size="1x" />
                          </Grid>
                          <Grid item>{t('transitPasses.purchase_pass')}</Grid>
                        </Grid>
                      </Button>
                    </Box>
                  )}
                  {expiredPasses.length > 0 && (
                    <Box>
                      <Divider light />
                      <Button
                        style={styles.addMethodButton}
                        onClick={() => setPassHistoryModalVisible(true)}
                      >
                        <Grid container direction="row" alignItems="center">
                          <Grid item style={styles.iconContainer}>
                            <FontAwesomeIcon icon={faAngleRight} color={colors.black} size="1x" />
                          </Grid>
                          <Grid item>{t('transitPasses.expired_pass')}</Grid>
                        </Grid>
                      </Button>
                    </Box>
                  )}
                  <Divider />
                </Box>
              )}
              {cardType && (
                <Box mb={2}>
                  {/* Show all passenger's saved stripe card */}
                  <Typography style={styles.paymentTitle}>{t('payment.card')}</Typography>
                  {currentPaymentMethods.map((card) => {
                    return (
                      <PaymentMethodItem
                        key={card.id}
                        paymentItem={cardType}
                        isSelected={card.id === paymentMethod?.id}
                        text={`${
                          card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)
                        } ${t('payment.card_ending_with')} **** ${card.card.last4}`}
                        cardDetails={card}
                        handleDetachCard={handleDetachCard}
                        detachText={t('labels_reusable.remove')}
                      />
                    );
                  })}

                  <Box>
                    <Divider light />
                    <Button
                      style={styles.addMethodButton}
                      onClick={() => setPaymentModalVisible(true)}
                    >
                      <Grid container direction="row" alignItems="center">
                        <Grid item style={styles.iconContainer}>
                          <FontAwesomeIcon icon={faPlus} color={colors.black} size="1x" />
                        </Grid>
                        <Grid item>{t('payment.add_new_card')}</Grid>
                      </Grid>
                    </Button>
                  </Box>
                  <Divider />
                </Box>
              )}
              {ticketType && (
                <Box mb={2}>
                  <Typography style={styles.paymentTitle}>{t('payment.tickets')}</Typography>
                  <PaymentMethodItem
                    key={ticketType.payment_type}
                    paymentItem={ticketType}
                    isSelected={ticketType.payment_type === paymentMethod?.type}
                    // TODO spanish trans
                    text={
                      LANGUAGE === 'en'
                        ? `${ticketType.translation.english}  $${(passengerBalance / 100).toFixed(
                            2
                          )}`
                        : `${ticketType.translation.french}  $${(passengerBalance / 100).toFixed(
                            2
                          )}`
                    }
                  />

                  <Box>
                    <Divider light />
                    <Button
                      style={styles.addMethodButton}
                      onClick={() => setPhysicalTicketModalVisible(true)}
                    >
                      <Grid container direction="row" alignItems="center">
                        <Grid item style={styles.iconContainer}>
                          <FontAwesomeIcon icon={faPlus} color={colors.black} size="1x" />
                        </Grid>
                        <Grid item>{t('payment.add_physical_ticket_number')}</Grid>
                      </Grid>
                    </Button>
                  </Box>
                  <Divider />
                </Box>
              )}
              {additionalPaymentTypes && (
                <Box mb={2}>
                  <Typography style={styles.paymentTitle}>
                    {t('payment.additional_payment_method')}
                  </Typography>
                  {additionalPaymentTypes.map((type) => {
                    return (
                      <PaymentMethodItem
                        key={type.payment_type}
                        paymentItem={type}
                        isSelected={type.payment_type === paymentMethod?.type}
                        // TODO spanish trans
                        text={
                          LANGUAGE === 'en' ? type.translation.english : type.translation.french
                        }
                      />
                    );
                  })}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              style={{ width: '100%', padding: '20px' }}
            >
              <Button color="primary" variant="contained" onClick={updateDefaultPaymentMethod}>
                {t('payment.set_default_payment')}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      {viewFrom === 'tripRequest' ? tripRequestView() : pageView()}
      {physicalTicketModalVisible && (
        <AddPhysicalTicketModal
          open={physicalTicketModalVisible}
          handleClose={setPhysicalTicketModalVisible}
          selectedOperator={selectedOperator}
          setPassengerBalance={setPassengerBalance}
        />
      )}
      {paymentModalVisible && (
        <AddCardPaymentModal
          open={paymentModalVisible}
          handleClose={setPaymentModalVisible}
          savePayment={savePayment}
          billingDetails={billingDetails}
          setError={setError}
          setBillingDetails={setBillingDetails}
        />
      )}
      {purchasePassModalVisible && (
        <PurchasePassModal
          transitPasses={transitPasses}
          open={purchasePassModalVisible}
          handleClose={setPurchasePassModalVisible}
          retrievePassengerPass={retrievePassengerPass}
          savePayment={savePayment}
          setError={setError}
          t={t}
          currentPaymentMethods={currentPaymentMethods}
          viewFrom={viewFrom}
          showPurchasePassSuccess={showPurchasePassSuccess}
        />
      )}
      {passHistoryModalVisible && (
        <PassHistoryModal
          open={passHistoryModalVisible}
          handleClose={() => setPassHistoryModalVisible(false)}
          expiredPasses={expiredPasses}
        />
      )}
    </>
  );
};

export default withRouter(Wallet);

const styles = {
  iconContainer: {
    width: '3em'
  },
  paymentTitle: {
    fontWeight: 'bold',
    color: colors.gray700
  },
  addMethodOption: {
    color: colors.blaisePurple,
    marginBottom: 5
  },
  addMethodButton: {
    color: colors.black,
    width: '100%'
  }
};
