import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import colors from '../../assets/sass/colors';
import { useAuth0 } from '@auth0/auth0-react';

const NoTransitAgencyFound = ({ setAccessToken }) => {
  const { t } = useTranslation('common');
  const { logout } = useAuth0();

  const handleLogout = () => {
    setAccessToken(null);
    localStorage.removeItem('state');
    logout({ returnTo: window.location.origin });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <Typography variant="h4">{t('console.oops')}</Typography>
      <Box style={{ marginBottom: '12px' }}>{t('operatorSelection.no_ta_message')}</Box>
      <Box display="flex" style={{ gap: '12px' }}>
        <Button
          style={{
            backgroundColor: colors.blaiseGreen,
            color: 'white',
            borderRadius: 5,
            padding: '5px 12px',
            margin: '10px 0',
            fontWeight: 'bold',
            textTransform: 'initial'
          }}
          onClick={() => location.reload()}
        >
          {t('operatorSelection.try_again')}
        </Button>
        <Button
          style={{
            backgroundColor: colors.blaiseGreen,
            color: 'white',
            borderRadius: 5,
            padding: '5px 12px',
            margin: '10px 0',
            fontWeight: 'bold',
            textTransform: 'initial'
          }}
          onClick={handleLogout}
        >
          {t('navbar.signout')}
        </Button>
      </Box>
    </Box>
  );
};

export default NoTransitAgencyFound;
