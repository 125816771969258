/* eslint-disable no-undef */
require('dotenv').config();

const appUrl = process.env.REACT_APP_URL;
const isDemo = process.env.REACT_APP_IS_DEMO === 'true' ? true : false;
const blaiseApiUrl = process.env.REACT_APP_BLAISE_API_URL;
const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TKN;
const mapboxReverseGeoUrl = process.env.REACT_APP_MAPBOX_REVERSE_GEO_URL;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const OSRMServer = process.env.REACT_APP_OSRM_SERVER;
const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
const firebaseDatabaseUrl = process.env.REACT_APP_FIREBASE_DATABASE_URL;
const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const firebaseMessagingId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const version = process.env.REACT_APP_VERSION;

const config = {
  appUrl,
  isDemo,
  blaiseApiUrl,
  mapboxToken,
  mapboxReverseGeoUrl,
  auth0Domain,
  auth0ClientId,
  auth0Audience,
  OSRMServer,
  firebaseApiKey,
  firebaseAuthDomain,
  firebaseDatabaseUrl,
  firebaseProjectId,
  firebaseStorageBucket,
  firebaseMessagingId,
  firebaseAppId,
  firebaseMeasurementId,
  stripeKey,
  version
};

export default config;
