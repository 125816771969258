export const TripStatus = Object.freeze({
  ACCEPTED: 'accepted',
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
  PROCESSING: 'processing',
  PENDING: 'pending',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  NO_SHOW: 'no_show',
  UNKNOWN: 'unknown',
  CANCELLED_PAYMENT: 'cancelled_payment',
  WAITING_FOR_PAYMENT: 'waiting_for_payment'
});
