import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Provider } from 'react-redux';
import axios from 'axios';
import './index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './components/App';
import common_fr from './translations/fr/common.json';
import common_en from './translations/en/common.json';
import common_es from './translations/es/common.json';
import store from './utils/redux/Store';
import Auth0ProviderWithHistory from './auth/auth0Provider';
import config from './config';

//Language detector detects the current language and initializes the page to use that language.
i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    en: {
      common: common_en // 'common' is our custom namespace
    },
    fr: {
      common: common_fr
    },
    es: {
      common: common_es
    }
  },
  load: 'languageOnly',
  lng: navigator.language || navigator.userLanguage,
  fallbackLng: 'en' // In case language detection fails, initialize to english
});

// Set up Sentry error monitoring
/* eslint-disable-next-line */
process.env.NODE_ENV === 'production' &&
  Sentry.init({
    dsn: 'https://46a84acfd3da4a3197bd104738152ffe@o495723.ingest.sentry.io/6060154',
    integrations: [new BrowserTracing()],

    // When debugging locally, set the tracesSampleRate to 1.0, and process.env.NODE_ENV === 'development'. On Production, we currently have the tracesSampleRate set to 0.2.
    tracesSampleRate: 0.2,
    release: config.version
  });

// Add global headers.
// "azp" tells the BE that it is an access token from the web passenger app.
axios.defaults.headers.common = {
  'token-type': 'azp',
  'x-app': 'passenger'
};

ReactDOM.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>,

  document.getElementById('root')
);
