import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../assets/sass/colors';
import { WalkingType } from '../../../enums/walkingType';
import { useTranslation } from 'react-i18next';

const WalkTimeDuration = ({ walkTime, type, maxWalkingDistance, stopTypeStrategy }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [displayString, setDisplayString] = useState('');
  const { t } = useTranslation('common');

  useEffect(() => {
    determineDisplayString();
  }, [walkTime, type, maxWalkingDistance, stopTypeStrategy]);

  const determineDisplayString = () => {
    // Reset state
    setShowWarning(false);
    setDisplayString('');

    const typeString =
      type === WalkingType.ORIGIN_TO_PICKUP
        ? t('tripReq.walkTo')
        : type === WalkingType.DROPOFF_TO_DESTINATION
        ? t('tripReq.walkFrom')
        : '';

    if (stopTypeStrategy === 1 && type === WalkingType.ORIGIN_TO_PICKUP) {
      setDisplayString(t('tripReq.pickupDoor'));
    } else if (stopTypeStrategy === 1 && type === WalkingType.DROPOFF_TO_DESTINATION) {
      setDisplayString(t('tripReq.dropoffDoor'));
    } else {
      const walkTimeMinutes = Math.max(1, Math.round(walkTime / 60));
      if (walkTimeMinutes >= maxWalkingDistance) {
        setShowWarning(true);
        setDisplayString(`${walkTimeMinutes} ${typeString}`);
      } else {
        setDisplayString(`${walkTimeMinutes} - ${maxWalkingDistance} ${typeString}`);
      }
    }
  };

  return (
    <>
      {showWarning && (
        <FontAwesomeIcon icon={faExclamationCircle} size="sm" style={{ color: colors.blaiseRed }} />
      )}
      <Typography
        variant="body2"
        style={{ color: showWarning ? colors.blaiseRed : colors.blaiseGray }}
      >
        {displayString}
      </Typography>
    </>
  );
};

export default WalkTimeDuration;
