import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import colors from '../../assets/sass/colors';
import { useTranslation } from 'react-i18next';

const Popup = ({
  open,
  handleClose,
  title,
  text,
  confirmButtonText,
  confirmAction,
  cancelButtonEnabled = true
}) => {
  const { t } = useTranslation('common');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ color: colors.black }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmAction} color="primary" variant="contained">
          {confirmButtonText}
        </Button>
        {cancelButtonEnabled && (
          <Button onClick={handleClose} color="secondary" variant="contained">
            {t('labels_reusable.cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
