import axios from 'axios';
import config from '../config';

export const getRoute = async (coords, accessToken) => {
  try {
    if (
      !accessToken ||
      !coords ||
      !coords.p1?.lon ||
      !coords.p1?.lat ||
      !coords.p2?.lon ||
      !coords.p2?.lat
    ) {
      return null;
    }

    const routeResponse = (
      await axios.post(`${config.blaiseApiUrl}/route`, coords, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    ).data;

    return routeResponse;
  } catch (error) {
    console.log('RoutesHelpers.js: error retrieving route with', coords);
  }
};
