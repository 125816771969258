import React, { useState, useEffect, useContext } from 'react';
import ReactMapboxGl, { Layer, Feature, Marker } from 'react-mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { mapContainer } from './MapView.module.scss';
import colors from '../../assets/sass/colors';
import config from '../../config';
import { AccessTokenContext } from '../App';
import { getRoute } from '../../utils/RoutesHelpers';

//Currently, this access token is for Ben's account. It is in the .env file, change it to official Blaise account when one is created.
const Map = ReactMapboxGl({
  accessToken: config.mapboxToken,
  interactive: true
});

const MapView = ({ ride, isFullScreen }) => {
  const [route1, setRoute1] = useState(ride.r1);
  const [route2, setRoute2] = useState(ride.r2);
  const [route3, setRoute3] = useState(ride.r3);
  const [originLong] = useState(Number(ride.origin_lon));
  const [originLat] = useState(Number(ride.origin_lat));
  const [pickupLong] = useState(Number(ride.pickup_lon));
  const [pickupLat] = useState(Number(ride.pickup_lat));
  const [dropoffLong] = useState(Number(ride.dropoff_lon));
  const [dropoffLat] = useState(Number(ride.dropoff_lat));
  const [destinationLong] = useState(Number(ride.dest_lon));
  const [destinationLat] = useState(Number(ride.dest_lat));
  const [centerLong] = useState((Number(ride.origin_lon) + Number(ride.dest_lon)) / 2);
  const [centerLat] = useState((Number(ride.origin_lat) + Number(ride.dest_lat)) / 2);
  const accessToken = useContext(AccessTokenContext);

  const setLines = async () => {
    // Getting routes between two points. We do this 3 times
    // r1 is route between origin and pickup (walking)
    // r2 is route between pickup and dropoff (driving)
    // r3 is route between dropoff and destination (walking)
    // Using these three points, we can plot on the map the three routes
    let r1Body = {
      p1: { lon: originLong, lat: originLat },
      p2: { lon: pickupLong, lat: pickupLat },
      walking: true
    };
    // Get list of coordinates for this route. This can then be transformed into a route on a map
    let r1 = null;

    try {
      r1 = await getRoute(r1Body, accessToken);
    } catch (err) {
      console.log(err);
    }

    let r2Body = {
      p1: { lon: pickupLong, lat: pickupLat },
      p2: { lon: dropoffLong, lat: dropoffLat },
      walking: false
    };
    //TODO need to figure out how to store/display the actual route the passenger took
    //Right now, we are storing the stops they get on and off at, but there's no way to keep track of if the bus took a detour
    //For these situations, should somehow track a polyline route that the bus took/the user walked and store it, to be displayed here after
    let r2 = null;

    try {
      r2 = await getRoute(r2Body, accessToken);
    } catch (err) {
      console.log(err);
    }

    let r3Body = {
      p1: { lon: dropoffLong, lat: dropoffLat },
      p2: { lon: destinationLong, lat: destinationLat },
      walking: true
    };

    let r3 = null;

    try {
      r3 = await getRoute(r3Body, accessToken);
    } catch (err) {
      console.log(err);
      r3 = null;
    }
    setRoute1(r1);
    setRoute2(r2);
    setRoute3(r3);
  };

  useEffect(() => {
    if (accessToken) {
      setLines();
    }
  }, [accessToken]);

  return (
    <Map
      style={'mapbox://styles/mapbox/light-v10'}
      zoom={[12]}
      className={mapContainer}
      containerStyle={{
        width: isFullScreen ? window.innerWidth : (3 * window.innerWidth) / 5
      }}
      center={[centerLong, centerLat]}
    >
      {route1 && route2 && route3 && (
        <div>
          <Layer key={'r1r3'} type="line" paint={{ 'line-width': 1.5, 'line-dasharray': [2, 1] }}>
            <Feature coordinates={route1} />
            <Feature coordinates={route3} />
          </Layer>
          <Layer
            key={'r2'}
            type="line"
            paint={{ 'line-width': 2, 'line-color': colors.blaiseGreen }}
          >
            <Feature coordinates={route2} />
          </Layer>
          <Layer
            type="circle"
            id="markers"
            paint={{
              'circle-color': colors.white,
              'circle-stroke-width': 1,
              'circle-stroke-color': colors.blaiseGreen,
              'circle-stroke-opacity': 1,
              'circle-radius': 2
            }}
          >
            <Feature coordinates={[pickupLong, pickupLat]} />
            <Feature coordinates={[dropoffLong, dropoffLat]} />
          </Layer>
        </div>
      )}
      {(!route1 || !route2 || !route3) && (
        <Layer
          key={'undeterminedPath'}
          type="line"
          paint={{ 'line-width': 1, 'line-dasharray': [2, 1], 'line-color': colors.blaiseGreen }}
        >
          <Feature
            coordinates={[
              [originLong, originLat],
              [destinationLong, destinationLat]
            ]}
          />
        </Layer>
      )}
      <Marker coordinates={[originLong, originLat]} anchor="center">
        <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaisePurple} size="2x" />
      </Marker>
      <Marker coordinates={[destinationLong, destinationLat]} anchor="center">
        <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaiseRed} size="2x" />
      </Marker>
    </Map>
  );
};

export default MapView;
