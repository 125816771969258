import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import colors from '../../assets/sass/colors';
import CompletedRides from '../../components/HistoricalRides/CompletedRides';
import CancelledRides from '../../components/HistoricalRides/CancelledRides';
import RejectedRides from '../../components/HistoricalRides/RejectedRides';
import NoShowRides from '../../components/HistoricalRides/NoShowRides';
import './HistoricalRides.scss';
import { useTranslation } from 'react-i18next';

const HistoricalRides = () => {
  const [rideStatus, setRideStatus] = useState('Completed');
  const { t } = useTranslation('common');

  const setDemand = (type) => {
    setRideStatus(type);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={2}
        style={{ alignContent: 'center' }}
      >
        <Select
          labelId="label"
          id="select"
          value={rideStatus}
          style={{ backgroundColor: colors.blaiseLightGreen, width: '30%', textAlign: 'center' }}
          disableUnderline
        >
          <MenuItem value="Completed" onClick={() => setDemand('Completed')}>
            {t('pastTrips.completed')}
          </MenuItem>
          <MenuItem value="Cancelled" onClick={() => setDemand('Cancelled')}>
            {t('pastTrips.cancelled')}
          </MenuItem>
          <MenuItem value="Rejected" onClick={() => setDemand('Rejected')}>
            {t('pastTrips.rejected')}
          </MenuItem>
          <MenuItem value="NoShow" onClick={() => setDemand('NoShow')}>
            {t('pastTrips.noShow')}
          </MenuItem>
        </Select>
        <Box>{rideStatus === 'Completed' && <CompletedRides />}</Box>
        <Box>{rideStatus === 'Cancelled' && <CancelledRides />}</Box>
        <Box>{rideStatus === 'Rejected' && <RejectedRides />}</Box>
        <Box>{rideStatus === 'NoShow' && <NoShowRides />}</Box>
      </Box>
    </>
  );
};

export default withRouter(HistoricalRides);
