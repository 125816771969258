import React, { useCallback, useContext, useEffect, useState } from 'react';
import { TextField, Select, MenuItem, Box, Button } from '@material-ui/core';
import { LocalizationProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import { faExchangeAlt, faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addMinutes } from 'date-fns';
import colors from '../../../assets/sass/colors';
import './WhereAndWhen.scss';
import AddressSearchResult from '../AddressSearchResult/AddressSearchResult';
import { debounce } from 'throttle-debounce';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import config from '../../../config';
import axios from 'axios';
import WalkTimeDuration from './WalkTimeDuration';
import { useTranslation } from 'react-i18next';
import Time from '../../../utils/Time';
import { WhenAndWhereMobileView } from '../../../enums/whenAndWhereMobileViews';
import MobileSearchAddressModal from '../MobileSearchAddressModal/MobileSearchAddressModal';
import { isMobile } from 'react-device-detect';
import { AccessTokenContext } from '../../App';
import { WalkingType } from '../../../enums/walkingType';
import { DateTimePickerFormatting } from '../../../utils/dateTimeFormats';

const LANGUAGE = navigator.language.substring(0, 2);

const WhereAndWhen = ({
  travelTimeData,
  nextStep,
  setMapOriginCoordinates,
  setMapDestinationCoordinates,
  tripRequestDate,
  setTripRequestDate
}) => {
  const [searchType, setSearchType] = useState(null);
  const [addressResults, setAddressResults] = useState([]);
  const [mobileView, setMobileView] = useState(WhenAndWhereMobileView.DEFAULT);
  const accessToken = useContext(AccessTokenContext);
  const { t } = useTranslation('common');
  const sessionToken = uuidv4();
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const originAddress = useSelector((state) => state.originAddress);
  const originCoords = useSelector((state) => state.originCoords);
  const destAddress = useSelector((state) => state.destAddress);
  const destCoords = useSelector((state) => state.destCoords);
  const travelPreference = useSelector((state) => state.travelPreference);
  const dispatch = useDispatch();

  useEffect(() => {
    if (originAddress && originCoords) {
      setMapOriginCoordinates(originCoords.lat, originCoords.lng);
    }
    if (destAddress && destCoords) {
      setMapDestinationCoordinates(destCoords.lat, destCoords.lng);
    }
  }, []);

  const handleInputChange = useCallback(
    debounce(500, (address) => {
      getAddress(address);
    }),
    []
  );

  const switchOriginAndDestination = () => {
    const newOriginAddress = originAddress;
    const newOriginCoords = originCoords;
    const newDestinationAddress = destAddress;
    const newDestinationCoords = destCoords;

    dispatch({ type: 'SET_ORIGIN_ADDRESS', payload: newDestinationAddress });
    dispatch({ type: 'SET_ORIGIN_COORDS', payload: newDestinationCoords });
    dispatch({ type: 'SET_DEST_ADDRESS', payload: newOriginAddress });
    dispatch({ type: 'SET_DEST_COORDS', payload: newOriginCoords });
  };

  // target: 'origin' | 'destination'
  const updateMapCoords = async (address, target) => {
    try {
      const response = (
        await axios.get(
          `${config.blaiseApiUrl}/phoneRequests/selectLocation?placeId=${address.placeId}&sessionToken=${sessionToken}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      const selectedLocation = response.location?.result;
      const coords = selectedLocation.geometry.location;
      const formattedAddress = selectedLocation.formatted_address;

      if (target === 'origin') {
        dispatch({ type: 'SET_ORIGIN_ADDRESS', payload: formattedAddress });
        dispatch({ type: 'SET_ORIGIN_COORDS', payload: coords });
        // used to set tripDuration: 0
        setMapOriginCoordinates(coords.lat, coords.lng);
      } else if (target === 'destination') {
        dispatch({ type: 'SET_DEST_ADDRESS', payload: formattedAddress });
        dispatch({ type: 'SET_DEST_COORDS', payload: coords });
        // used to set tripDuration: 0
        setMapDestinationCoordinates(coords.lat, coords.lng);
      } else {
        console.log('WhereAndWhen::updateMapCoords - target is not origin or destination.');
      }
    } catch (err) {
      console.log('WhereAndWhen::updateMapCoords -', err);
    }
  };

  const getAddress = async (location) => {
    if (location.length >= 3) {
      const addressSuggestions = (
        await axios.get(
          `${config.blaiseApiUrl}/phoneRequests/searchLocation?locationString=${location}&transitAgencyId=${selectedTransitAgency?.transit_agency_id}&sessionToken=${sessionToken}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data.suggestions;

      const formattedSuggestions = addressSuggestions.map((suggestion) => {
        return {
          mainText: suggestion.structured_formatting.main_text,
          secondaryText: suggestion.structured_formatting.secondary_text,
          placeId: suggestion.place_id
        };
      });

      setAddressResults(formattedSuggestions);
    }
  };

  const goToAddressSearchView = (locationType) => {
    setSearchType(locationType);
    setMobileView(WhenAndWhereMobileView.SEARCH_ADDRESS);
  };

  return (
    <Box className="where-and-when-container">
      {isMobile ? (
        <>
          {mobileView === WhenAndWhereMobileView.DEFAULT ? (
            <>
              <Box style={{ height: '240px' }}>
                <Box
                  style={{
                    padding: '30px 10px 10px'
                  }}
                  display="flex"
                >
                  {/* Dots */}
                  <Box className="dot-container">
                    <FontAwesomeIcon color={colors.blaisePurple} icon={faCircle} size="lg" />
                    <hr />
                    <FontAwesomeIcon color={colors.blaiseRed} icon={faMapMarkerAlt} size="lg" />
                  </Box>

                  {/* Origin/Destination Container */}
                  <Box className="origin-destination-container">
                    {/* Origin */}
                    <Button
                      onClick={() => goToAddressSearchView('origin')}
                      className="origin-destination-mobile-button"
                    >
                      <TextField
                        label={t('tripReq.origin')}
                        variant="outlined"
                        fullWidth={true}
                        value={originAddress}
                        /* Styles the wrapper */
                        style={{ height: '44px', marginBottom: '32px' }}
                        /* Styles the input component */
                        inputProps={{
                          style: {
                            height: '44px',
                            padding: '0 14px'
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            top: '-5px'
                          }
                        }}
                      />
                    </Button>
                    {travelTimeData?.finalTravelTime && (
                      <Box style={{ top: '42px' }} className="walking-distance">
                        <WalkTimeDuration
                          walkTime={travelTimeData?.walkTimeOriginToPickup}
                          type={WalkingType.ORIGIN_TO_PICKUP}
                          maxWalkingDistance={Number(selectedTransitAgency?.max_walking_distance)}
                          stopTypeStrategy={travelTimeData?.pickupStopStrategy}
                        />
                      </Box>
                    )}

                    {/* Destination */}
                    <Button
                      onClick={() => goToAddressSearchView('destination')}
                      className="origin-destination-mobile-button"
                    >
                      <TextField
                        label={t('tripReq.destination')}
                        variant="outlined"
                        fullWidth={true}
                        value={destAddress}
                        /* Styles the wrapper */
                        style={{ height: '44px' }}
                        /* Styles the input component */
                        inputProps={{
                          style: {
                            height: '44px',
                            padding: '0 14px'
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            top: '-5px'
                          }
                        }}
                      />
                    </Button>
                    {travelTimeData?.finalTravelTime && (
                      <Box style={{ bottom: '-20px' }} className="walking-distance">
                        <WalkTimeDuration
                          walkTime={travelTimeData?.walkTimeOriginToPickup}
                          type={WalkingType.DROPOFF_TO_DESTINATION}
                          maxWalkingDistance={Number(selectedTransitAgency?.max_walking_distance)}
                          stopTypeStrategy={travelTimeData?.pickupStopStrategy}
                        />
                      </Box>
                    )}
                  </Box>

                  {/* Switch Origin/Destination */}
                  <Box className="switch-input-container">
                    <Button onClick={switchOriginAndDestination}>
                      <FontAwesomeIcon
                        style={{ transform: 'rotate(90deg)' }}
                        color={colors.black}
                        icon={faExchangeAlt}
                        size="lg"
                      />
                    </Button>
                  </Box>
                </Box>
                <Box display="flex" style={{ gap: '16px', padding: '20px' }}>
                  {/* Travel preference type */}
                  <Select
                    variant="outlined"
                    value={travelPreference}
                    onChange={(e) =>
                      dispatch({ type: 'SET_TRAVEL_PREFERENCE', payload: e.target.value })
                    }
                    className="travel-preference-dropdown"
                  >
                    <MenuItem value="DEPART_AT">{t('tripReq.depDateTime')}</MenuItem>
                    <MenuItem value="ARRIVE_BY">{t('tripReq.arrDateTime')}</MenuItem>
                  </Select>

                  {/* Date & Time */}
                  <Box className="date-field">
                    <LocalizationProvider dateAdapter={MomentUtils}>
                      <DateTimePicker
                        minDateTime={addMinutes(
                          new Date(),
                          Time.convertTimeToMinutes(selectedTransitAgency?.request_window_low)
                        )}
                        maxDateTime={addMinutes(
                          new Date(),
                          Time.convertTimeToMinutes(selectedTransitAgency?.request_window_high)
                        )}
                        value={
                          tripRequestDate
                            ? new Date(tripRequestDate)
                            : addMinutes(
                                new Date(),
                                Time.convertTimeToMinutes(selectedTransitAgency?.request_window_low)
                              )
                        }
                        onChange={(value) => setTripRequestDate(String(value))}
                        inputFormat={
                          LANGUAGE === 'en'
                            ? DateTimePickerFormatting.en
                            : DateTimePickerFormatting.fr
                        }
                        renderInput={(props) => {
                          return <TextField {...props} fullWidth helperText={null} error={false} />;
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>
              <Box className="next-button">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={nextStep}
                  disabled={!originAddress || !destAddress || !originCoords || !destCoords}
                >
                  {t('labels_reusable.next')}
                </Button>
              </Box>
            </>
          ) : (
            <MobileSearchAddressModal
              open={mobileView === WhenAndWhereMobileView.SEARCH_ADDRESS}
              setMobileView={setMobileView}
              addressResults={addressResults}
              searchType={searchType}
              originAddress={originAddress}
              destAddress={destAddress}
              setAddressResults={setAddressResults}
              updateMapCoords={updateMapCoords}
              handleInputChange={handleInputChange}
            />
          )}
        </>
      ) : (
        <>
          <Box
            className="input-section"
            style={{
              borderBottom: `${addressResults.length > 0 ? `1px solid ${colors.gray300}` : 'none'}`
            }}
          >
            <Box
              style={{
                padding: '30px 20px 40px 20px',
                borderBottom: `1px solid ${colors.gray300}`
              }}
              display="flex"
            >
              {/* Dots */}
              <Box className="dot-container">
                <FontAwesomeIcon color={colors.blaisePurple} icon={faCircle} size="lg" />
                <hr />
                <FontAwesomeIcon color={colors.blaiseRed} icon={faMapMarkerAlt} size="lg" />
              </Box>

              {/* Origin/Destination Container */}
              <Box className="origin-destination-container">
                {/* Origin */}
                <Box style={{ position: 'relative' }}>
                  <TextField
                    placeholder={t('tripReq.origin')}
                    variant="outlined"
                    fullWidth={true}
                    value={originAddress}
                    onChange={(e) => {
                      setSearchType('origin');
                      dispatch({ type: 'SET_ORIGIN_ADDRESS', payload: e.target.value });
                      handleInputChange(e.target.value);
                    }}
                    /* Styles the wrapper */
                    style={{ height: '44px', marginBottom: '40px' }}
                    /* Styles the input component */
                    inputProps={{
                      style: {
                        height: '44px',
                        padding: '0 14px'
                      }
                    }}
                  />
                  {travelTimeData?.finalTravelTime && (
                    <Box style={{ bottom: '14px' }} className="walking-distance">
                      <WalkTimeDuration
                        walkTime={travelTimeData?.walkTimeOriginToPickup}
                        type={WalkingType.ORIGIN_TO_PICKUP}
                        maxWalkingDistance={Number(selectedTransitAgency?.max_walking_distance)}
                        stopTypeStrategy={travelTimeData?.pickupStopStrategy}
                      />
                    </Box>
                  )}
                </Box>

                {/* Destination */}
                <Box style={{ position: 'relative' }}>
                  <TextField
                    placeholder={t('tripReq.destination')}
                    variant="outlined"
                    fullWidth={true}
                    value={destAddress}
                    onChange={(e) => {
                      setSearchType('destination');
                      dispatch({ type: 'SET_DEST_ADDRESS', payload: e.target.value });
                      handleInputChange(e.target.value);
                    }}
                    /* Styles the wrapper */
                    style={{ height: '44px' }}
                    /* Styles the input component */
                    inputProps={{
                      style: {
                        height: '44px',
                        padding: '0 14px'
                      }
                    }}
                  />
                  {travelTimeData?.finalTravelTime && (
                    <Box style={{ bottom: '-24px' }} className="walking-distance">
                      <WalkTimeDuration
                        walkTime={travelTimeData?.walkTimeOriginToPickup}
                        type={WalkingType.DROPOFF_TO_DESTINATION}
                        maxWalkingDistance={Number(selectedTransitAgency?.max_walking_distance)}
                        stopTypeStrategy={travelTimeData?.pickupStopStrategy}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Switch Origin/Destination */}
              <Box className="switch-input-container">
                <Button onClick={switchOriginAndDestination}>
                  <FontAwesomeIcon
                    style={{ transform: 'rotate(90deg)' }}
                    color={colors.black}
                    icon={faExchangeAlt}
                    size="lg"
                  />
                </Button>
              </Box>
            </Box>

            <Box display="flex" style={{ gap: '16px', padding: '20px' }}>
              {/* Travel preference type */}
              <Select
                variant="outlined"
                value={travelPreference}
                onChange={(e) =>
                  dispatch({ type: 'SET_TRAVEL_PREFERENCE', payload: e.target.value })
                }
                className="travel-preference-dropdown"
              >
                <MenuItem value="DEPART_AT">{t('tripReq.depDateTime')}</MenuItem>
                <MenuItem value="ARRIVE_BY">{t('tripReq.arrDateTime')}</MenuItem>
              </Select>

              {/* Date & Time */}
              <Box className="date-field">
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DateTimePicker
                    minDateTime={addMinutes(
                      new Date(),
                      Time.convertTimeToMinutes(selectedTransitAgency?.request_window_low)
                    )}
                    maxDateTime={addMinutes(
                      new Date(),
                      Time.convertTimeToMinutes(selectedTransitAgency?.request_window_high)
                    )}
                    value={
                      tripRequestDate
                        ? new Date(tripRequestDate)
                        : addMinutes(
                            new Date(),
                            Time.convertTimeToMinutes(selectedTransitAgency?.request_window_low)
                          )
                    }
                    onChange={(value) => setTripRequestDate(String(value))}
                    inputFormat={
                      LANGUAGE === 'en' ? DateTimePickerFormatting.en : DateTimePickerFormatting.fr
                    }
                    renderInput={(props) => {
                      return <TextField {...props} fullWidth helperText={null} error={false} />;
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
          </Box>

          {/* Address Results */}
          {addressResults.length > 0 && (
            <Box className="address-search-result-container">
              {addressResults.map((address, index) => (
                <AddressSearchResult
                  key={index}
                  address={address}
                  searchType={searchType}
                  setAddressResults={setAddressResults}
                  updateMapCoords={updateMapCoords}
                />
              ))}
            </Box>
          )}

          <Box className="next-button">
            <Button
              color="primary"
              variant="contained"
              onClick={nextStep}
              disabled={!originAddress || !destAddress || !originCoords || !destCoords}
            >
              {t('labels_reusable.next')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default WhereAndWhen;
