import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

export const MountAnimation = ({
  children,
  timeout = 500, // This needs to match your .SCSS animation duration.
  isVisible = false,
  unmountOnExit = true,
  classNames = 'transition-fade', // CSSTransition appends -enter and -enter-active to this class.
  ...restProps
}) => {
  const [isMounted, setIsMounted] = useState(isVisible);

  useEffect(() => {
    setIsMounted(isVisible);
  }, [isVisible]);

  return (
    <CSSTransition
      in={isMounted}
      timeout={timeout}
      classNames={classNames}
      unmountOnExit={unmountOnExit}
      {...restProps}
    >
      <>{children}</>
    </CSSTransition>
  );
};
