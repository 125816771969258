import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
  TextField
} from '@material-ui/core';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import colors from '../../assets/sass/colors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export const ExtraDetailsModal = ({
  isOpen,
  openModal,
  requiredPassengerDetails,
  setRequiredPassengerDetails,
  transitAgencyId,
  user,
  accessToken
}) => {
  const LANGUAGE = navigator.language.substring(0, 2);

  const { t } = useTranslation('common');
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);
  const initialPassengerType = useSelector((state) => state.userPassengerType);
  const [passengerType, setPassengerType] = useState(initialPassengerType?.passenger_type_id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setHasError(() =>
      requiredPassengerDetails.some(
        (element) =>
          (element.has_options && !element.optionIdValue) ||
          (!element.has_options && !element.textValue)
      )
    );
  }, [requiredPassengerDetails]);

  const updateOptionIdValue = (value, key) => {
    setRequiredPassengerDetails((prevState) => {
      return prevState.map((element) => {
        if (element.title_key === key) {
          return {
            ...element,
            optionIdValue: value
          };
        }
        return element;
      });
    });
  };

  const updateTextValue = (value, key) => {
    setRequiredPassengerDetails((prevState) => {
      return prevState.map((element) => {
        if (element.title_key === key) {
          return {
            ...element,
            textValue: value
          };
        }
        return element;
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const reqBody = {
      taExtraDetails: {
        ...requiredPassengerDetails
      }
    };

    try {
      if (requiredPassengerDetails.length > 0) {
        await axios.put(
          `${config.blaiseApiUrl}/passengers/${user.sub}/taSettings/${transitAgencyId}/create`,
          reqBody,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );
      }
      if (passengerType) {
        axios.put(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transitAgencyId}/passengerType`,
          {
            passenger_type_id: passengerType
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        );

        // Update passenger type in global state
        dispatch({
          type: 'SET_USER_PASSENGER_TYPE',
          payload: { ...initialPassengerType, passenger_type_id: passengerType }
        });
      }
      openSnackbar(t('profile.changesSaved'), colors.blaiseGreen);
      setIsSubmitting(false);
      openModal(false);
    } catch (error) {
      console.log(error);
      openSnackbar(t('profile.changesNotSaved'), colors.red);
      setIsSubmitting(false);
    }
  };

  // Prevents modal from closing when clicking outside or pressing escape
  const handleCloseModal = (event, reason) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    openModal(false);
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };
  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Dialog
        open={isOpen}
        onClose={handleCloseModal}
        scroll="paper"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{t('profile.setting_up_profile')}</DialogTitle>
        <DialogContent dividers={true}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={2}
            style={{ width: '100%' }}
          >
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel id="passengerTypeLabel">
                {`${t('passenger.select_passenger_type')} *`}
              </InputLabel>
              <Select
                error={!passengerType}
                disabled={isSubmitting}
                value={passengerType}
                onChange={(e) => setPassengerType(e.target.value)}
                label="passengerTypeLabel"
              >
                {taPassengerTypes.map((type) => (
                  <MenuItem value={type.id} key={type.id}>
                    {LANGUAGE === 'en' ? type.english : type.french}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('labels_reusable.required')}</FormHelperText>
            </FormControl>
          </Box>
          {requiredPassengerDetails && requiredPassengerDetails.length > 0 ? (
            <Box py={2}>
              <Typography style={{ paddingBottom: '1rem' }}>
                {t('profile.additional_details')}
              </Typography>
              <Grid container spacing={2}>
                {requiredPassengerDetails.map((pref, index) => {
                  if (pref.transit_agency_preference.is_editable_passenger) {
                    return (
                      <Grid item key={`pref-${pref.preference_id}-${index}`} xs={12} sm={6}>
                        <FormControl
                          key={`pref-${pref.preference_id}-${index}`}
                          style={{ width: '100%' }}
                          variant="outlined"
                        >
                          {pref.has_options ? (
                            <>
                              <InputLabel id={pref.title_key}>
                                {LANGUAGE === 'en'
                                  ? pref.translationTitle.english
                                  : pref.translationTitle.french}
                                {pref.is_required && ' *'}
                              </InputLabel>
                              <Select
                                error={!pref.optionIdValue}
                                disabled={isSubmitting}
                                value={pref.optionIdValue}
                                label={pref.title}
                                onChange={(e) =>
                                  updateOptionIdValue(e.target.value, pref.title_key)
                                }
                              >
                                {pref.preference_options.map((option) => (
                                  <MenuItem
                                    value={option.value}
                                    key={`pref-${option.preference_id}`}
                                  >
                                    {LANGUAGE === 'en'
                                      ? option.translationValue.english
                                      : option.translationValue.french}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          ) : (
                            <TextField
                              required
                              variant="outlined"
                              error={!pref.textValue}
                              disabled={isSubmitting}
                              label={
                                LANGUAGE === 'en'
                                  ? pref.translationTitle.english
                                  : pref.translationTitle.french
                              }
                              value={pref.textValue}
                              onChange={(e) => updateTextValue(e.target.value, pref.title_key)}
                              style={{ maxWidth: 'unset' }}
                            />
                          )}
                          {pref.is_required &&
                          ((pref.has_options && !pref.optionIdValue) ||
                            (!pref.has_options && !pref.textValue)) ? (
                            <FormHelperText
                              error={
                                (pref.has_options && !pref.optionIdValue) ||
                                (!pref.has_options && !pref.textValue)
                              }
                            >
                              {t('labels_reusable.required')}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={hasError || isSubmitting}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {t('labels_reusable.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
