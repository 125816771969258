import React from 'react';
import DownloadTheAppQRCode from '../../components/DownloadTheAppQRCode/DownloadTheAppQRCode';
import Box from '@material-ui/core/Box';

const DownloadTheApp = () => {
  return (
    // Margin added for the /downloadTheApp page, but not for the component itself (for when used on /viewRide popup).
    <Box style={{ marginTop: '16px' }}>
      <DownloadTheAppQRCode />
    </Box>
  );
};

export default DownloadTheApp;
