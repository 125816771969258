import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import ReactMapboxGl, { Marker, Layer, Feature, GeoJSONLayer } from 'react-mapbox-gl';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import colors from '../../assets/sass/colors';
import './HomeMap.scss';
import TripRequest from '../../components/TripRequest/TripRequest';
import * as turf from '@turf/turf';
import { withRouter } from 'react-router';
import busStopIcon from '../../assets/images/bus-stop.svg';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { AccessTokenContext, GeoJSONContext } from '../../components/App';
import { ExtraDetailsModal } from '../../components/ExtraDetailsModal/ExtraDetailsModal';

const Map = ReactMapboxGl({
  accessToken: config.mapboxToken
});

const image = new Image(12, 12);
image.src = busStopIcon;
const images = ['myImage', image];

const HomeMap = () => {
  const [routeLine, setRouteLine] = useState(null);
  const [requiredPassengerDetails, setRequiredPassengerDetails] = useState([]);
  const [taExtraDetails, setTaExtraDetails] = useState([]);
  const [extraDetailsModalOpen, setExtraDetailsModalOpen] = useState(false);
  const hasSetTaExtraDetailsOnce = useRef(false);
  const needsExtraDetails = useRef(false);
  const { user } = useAuth0();
  const accessToken = useContext(AccessTokenContext);
  const transitAgencyGeoJSON = useContext(GeoJSONContext);
  const mapCentre = useSelector((state) => state.mapCentre);
  const originCoords = useSelector((state) => state.originCoords);
  const destCoords = useSelector((state) => state.destCoords);
  const busStopsForTa = useSelector((state) => state.busStopsForTa);
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);
  const passengerType = useSelector((state) => state.userPassengerType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTransitAgency && user.sub && taPassengerTypes.length > 0) {
      verifyPassengerExtraDetails();
    }
  }, [user.sub, selectedTransitAgency, taPassengerTypes]);

  useEffect(() => {
    // Also open the modal if the user doesn't have a passenger type
    if (!passengerType) {
      setExtraDetailsModalOpen(true);
    } else {
      setExtraDetailsModalOpen(() => needsExtraDetails.current || false);
    }
  }, [passengerType]);

  useEffect(() => {
    // Only run this useEffect once after the taExtraDetails state has been set
    if (hasSetTaExtraDetailsOnce.current) return;

    const updatedTaExtraDetails = taExtraDetails.map((pref) => {
      if (pref.has_options) {
        return { ...pref, optionIdValue: null };
      } else {
        return { ...pref, textValue: null };
      }
    });

    setTaExtraDetails(updatedTaExtraDetails);
    hasSetTaExtraDetailsOnce.current = true;
  }, [taExtraDetails]);

  const setMapOriginCoordinates = (lat, lng) => {
    let coords = null;

    if (destCoords) {
      coords = turf.lineString([
        [lng, lat],
        [destCoords.lng, destCoords.lat]
      ]).geometry.coordinates;
    }
    dispatch({ type: 'SET_ORIGIN_COORDS', payload: { lat, lng } });
    dispatch({ type: 'SET_MAP_CENTRE', payload: { lat, lng } });
    setRouteLine(coords);
  };

  const setMapDestinationCoordinates = (lat, lng) => {
    let coords = null;

    if (originCoords) {
      coords = turf.lineString([
        [originCoords.lng, originCoords.lat],
        [lng, lat]
      ]).geometry.coordinates;
    }

    dispatch({ type: 'SET_DEST_COORDS', payload: { lat, lng } });
    dispatch({ type: 'SET_MAP_CENTRE', payload: { lat, lng } });
    setRouteLine(coords);
  };

  const linePaint = (ReactMapboxGl.LinePaint = {
    'line-color': colors.blaiseGray,
    'line-width': 2,
    'line-dasharray': [0, 2]
  });

  const lineLayout = {
    'line-cap': 'round'
  };

  const verifyPassengerExtraDetails = async () => {
    try {
      // Passenger extra details
      const passengerExtraDetails = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/taSettings/${selectedTransitAgency.transit_agency_id}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      // All required details by the TA
      const taRequiredDetails = (
        await axios.get(
          `${config.blaiseApiUrl}/transitagencies/${selectedTransitAgency.transit_agency_id}/extraDetails`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data.preferences;

      let detailsExists = false;

      // All the required details by the TA that have not been answered by the passenger
      const requiredPassengerDetails = [];

      // Check if TA has required extra details, if not then skip
      if (taRequiredDetails.length !== 0) {
        // Check if the number of TA required extra details is the same as the number of passenger extra details
        taRequiredDetails.forEach((taElement) => {
          detailsExists = false;
          passengerExtraDetails.forEach((passengerElement) => {
            if (taElement.preference_id === passengerElement.preference_id) {
              detailsExists = true;
            }
          });
          if (!detailsExists && taElement.transit_agency_preference.is_editable_passenger) {
            requiredPassengerDetails.push(taElement);
          }
        });
        // Sort the array so that the required fields are on top
        requiredPassengerDetails.sort((x, y) => Number(y.is_required) - Number(x.is_required));

        setRequiredPassengerDetails(requiredPassengerDetails);
        setTaExtraDetails(taRequiredDetails);

        if (requiredPassengerDetails.length > 0) {
          needsExtraDetails.current = true;
          setExtraDetailsModalOpen(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box style={{ position: 'relative' }}>
      {!passengerType || (taExtraDetails && taExtraDetails.length !== 0) ? (
        <ExtraDetailsModal
          isOpen={extraDetailsModalOpen}
          openModal={setExtraDetailsModalOpen}
          requiredPassengerDetails={requiredPassengerDetails}
          setRequiredPassengerDetails={setRequiredPassengerDetails}
          transitAgencyId={selectedTransitAgency.transit_agency_id}
          passengerType={passengerType}
          taPassengerTypes={taPassengerTypes}
          user={user}
          accessToken={accessToken}
        />
      ) : null}
      {mapCentre && (
        <Map
          tabindex="0"
          id="map"
          role="application"
          aria-label="Map"
          style={'mapbox://styles/mapbox/light-v10'}
          zoom={[12]}
          className="map"
          center={[mapCentre.lng, mapCentre.lat]}
          containerStyle={{
            height: `${isMobile ? `calc(${window.innerHeight}px - 48px - 318.5px)` : '100vh'}`, // 48px for navbar, 318.5px for stepper container.
            width: '100vw'
          }}
        >
          {busStopsForTa && (
            <Layer
              type="symbol"
              id="marker"
              layout={{ 'icon-image': 'myImage', 'icon-allow-overlap': true }}
              images={images}
              minZoom={11}
            >
              {busStopsForTa.busStops.map((busStop) => {
                const stopCoords = [Number(busStop.coord[0]), Number(busStop.coord[1])];
                return <Feature key={busStop.id} coordinates={stopCoords} />;
              })}
            </Layer>
          )}

          {originCoords && (
            <Marker coordinates={originCoords} anchor="bottom">
              <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaisePurple} size="2x" />
            </Marker>
          )}
          {destCoords && (
            <Marker coordinates={destCoords} anchor="bottom">
              <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaiseRed} size="2x" />
            </Marker>
          )}

          {routeLine && (
            <Layer type="line" paint={linePaint} layout={lineLayout}>
              <Feature coordinates={routeLine} />
            </Layer>
          )}
          {transitAgencyGeoJSON && (
            <GeoJSONLayer
              data={transitAgencyGeoJSON}
              fillPaint={{
                'fill-color': colors.blaiseGreen,
                'fill-opacity': 0.2
              }}
            ></GeoJSONLayer>
          )}
        </Map>
      )}
      <TripRequest
        setMapOriginCoordinates={setMapOriginCoordinates}
        setMapDestinationCoordinates={setMapDestinationCoordinates}
      />
    </Box>
  );
};

export default withRouter(HomeMap);
