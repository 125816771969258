import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Rating from '@material-ui/lab/Rating';
import { Box, Button, CircularProgress, withStyles } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import colors from '../../assets/sass/colors';
import MapView from '../../components/MapView/MapView';
import Destinations from '../../components/Destinations/Destinations';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import { useHistory } from 'react-router';
import ROUTES from '../../utils/routes';
import { AccessTokenContext } from '../../components/App';
import { dateFormat } from '../../utils/dateTimeFormats';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//Styling for the rating displayed
const StyledRating = withStyles({
  iconFilled: {
    color: colors.blaiseGreen
  }
})(Rating);

const TripDetails = () => {
  // Would be nice to replace this with useSearchParams when upgrade to Router V6
  let url = new URL(window.location.href);
  let result;
  const [ride, setRide] = useState(null);
  const [type] = useState(url.searchParams.get('type'));
  const accessToken = useContext(AccessTokenContext);
  const { user } = useAuth0();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const history = useHistory();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const cancelRide = async (rideID, redirect) => {
    try {
      await axios.put(
        `${config.blaiseApiUrl}/passengers/${user.sub}/rides/${rideID}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (redirect) history.push(ROUTES.history);
    } catch (err) {
      openSnackbar(t('viewTrips.notCancelled'), colors.red);
    }
  };

  useEffect(() => {
    switch (type) {
      case 'pending':
      case 'rejected':
        (async () => {
          result = (
            await axios.get(
              `${config.blaiseApiUrl}/passengers/${user.sub}/requests/${url.searchParams.get(
                'rideID'
              )}`,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
          ).data;
          setRide(result);
        })();
        break;
      case 'completed':
        (async () => {
          result = (
            await axios.get(
              `${config.blaiseApiUrl}/rides/${url.searchParams.get('actualRideID')}/actualRide`,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
          ).data[0];
          setRide(result);
        })();
        break;
      default:
        (async () => {
          result = (
            await axios.get(`${config.blaiseApiUrl}/rides/${url.searchParams.get('rideID')}`, {
              headers: { Authorization: `Bearer ${accessToken}` }
            })
          ).data;
          setRide(result);
        })();
        break;
    }
  }, []);

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  const viewReceipt = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />

      {ride === null && (
        <Box className="blaise-progress-top">
          <CircularProgress aria-label="Progress Icon" aria-busy="true" />
        </Box>
      )}

      {ride && (
        <Box display="flex" flexDirection="column" alignItems="center" pt={2}>
          <MapView ride={ride} isFullScreen={isMobile} />

          <Box
            style={{
              width: isMobile ? window.innerWidth : (3 * window.innerWidth) / 5,
              marginTop: 8
            }}
          >
            <Box ml={2} mb={2} fontWeight="bold">
              {ride.departure_time
                ? dateFormat(new Date(ride.departure_time))
                : dateFormat(new Date(ride.earliest_departure))}
            </Box>

            <Destinations data={ride} type={type} />

            <Box marginTop="20px" mx={2}>
              {ride.payment_type && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>{t('profile.pay')}: </span>
                  <span>{t(`payment.${ride.payment_type}`)}</span>
                </div>
              )}
              {typeof ride.trip_price === 'number' && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>{t('payment.total')}: </span>
                  <span>${(ride.trip_price / 100).toFixed(2)}</span>
                </div>
              )}
            </Box>

            {ride.passenger_rating && (
              <Box flexDirection="row" display="flex" mx={2} my={3} alignItems="center">
                <div style={{ fontWeight: 'bold', paddingRight: '1.5em' }}>
                  {t('tripDetails.rating')}
                </div>
                <StyledRating
                  style={{ fontSize: '1.5em' }}
                  value={ride.passenger_rating}
                  readOnly
                />
              </Box>
            )}

            {ride.passenger_comment && (
              <Box flexDirection="column" display="flex" mx={2} my={3}>
                <div style={{ fontWeight: 'bold' }}>{t('tripDetails.comments')}</div>
                <div>{ride.passenger_comment}</div>
              </Box>
            )}

            {ride.stripe_receipt_url && (
              <Box flexDirection="row" display="flex" my={3}>
                <Button
                  onClick={() => {
                    viewReceipt(ride.stripe_receipt_url);
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  {t('tripDetails.viewReceipt')}
                </Button>
              </Box>
            )}

            {type === 'upcoming' && (
              <Box
                flexDirection="row"
                display="flex"
                justifyContent="center"
                mx={2}
                my={3}
                flexWrap="wrap"
              >
                <Button
                  color="primary"
                  disabled={!ride.route_id}
                  onClick={() => {
                    dispatch({ type: 'SET_RIDE', payload: ride });
                    history.push(ROUTES.tripProcess);
                  }}
                  type="button"
                  variant="contained"
                >
                  {t('comingTrips.startTrip')}
                </Button>
                <Button
                  disabled={!ride.ride_proposal_id}
                  color="secondary"
                  onClick={() => {
                    if (window.confirm(t('viewTrips.cancelConfirm'))) {
                      cancelRide(ride.ride_proposal_id, true);
                    }
                  }}
                  type="button"
                  variant="contained"
                >
                  {t('comingTrips.cancelTrip')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TripDetails;
