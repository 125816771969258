export const checkIfBlaiseEmployeeOrUATUser = (user) => {
  const specialRoles = ['Blaise Admin', 'UAT User']; // Auth0 blaise-transit role names.
  const usersRoles = user['http://blaisetransitagency.com/roles']; // Url is specified in Auth0's Auth Pipeline > Rules.
  const isBlaiseEmployeeOrUATUser = specialRoles.find((element) => usersRoles?.includes(element)); // Returns a string if found, undefined if not.

  if (user && isBlaiseEmployeeOrUATUser) {
    return true;
  }

  return false;
};
