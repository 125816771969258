import { TravelPreferenceType } from '../../enums/TravelPreferenceType';

// even though we dont use all of these in the web appp right now, for right now I'm jsut going to keep all of these for reference!
export const initialState = {
  ride: null,
  operatorSelected: null,
  originAddress: null,
  originCoords: null,
  destAddress: null,
  destCoords: null,
  passengers: null,
  sidebarOpen: true,
  currentLocation: null,
  busStopsForTa: null,
  mapCentre: { lat: -73.567531, lng: 45.506595 },
  paymentMethod: null,
  travelPreference: TravelPreferenceType.DEPART_AT,
  priceObject: {},
  taPassengerTypes: [],
  userPassengerType: null,
  transitAgencyFeatures: []
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RIDE':
      return {
        ...state,
        ride: action.payload
      };
    case 'SET_OPERATOR_SELECTED':
      return {
        ...state,
        operatorSelected: action.payload
      };
    case 'SET_ORIGIN_ADDRESS':
      return {
        ...state,
        originAddress: action.payload
      };
    case 'SET_ORIGIN_COORDS':
      return {
        ...state,
        originCoords: action.payload
      };
    case 'SET_DEST_ADDRESS':
      return {
        ...state,
        destAddress: action.payload
      };
    case 'SET_DEST_COORDS':
      return {
        ...state,
        destCoords: action.payload
      };
    case 'SET_PASSENGERS':
      return {
        ...state,
        passengers: action.payload
      };
    case 'SET_SIDEBAR_OPEN':
      return {
        ...state,
        sidebarOpen: action.payload
      };
    case 'SET_CURRENT_LOCATION':
      return {
        ...state,
        currentLocation: action.payload
      };
    case 'SET_BUS_STOPS_FOR_TA':
      return {
        ...state,
        busStopsForTa: action.payload
      };
    case 'SET_MAP_CENTRE':
      return {
        ...state,
        mapCentre: action.payload
      };
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload
      };
    case 'SET_DEFAULT_PAYMENT_METHOD':
      return {
        ...state,
        defaultPaymentMethod: action.payload
      };
    case 'SET_TRAVEL_PREFERENCE':
      return {
        ...state,
        travelPreference: action.payload
      };
    case 'SET_PRICE_OBJECT':
      return {
        ...state,
        priceObject: action.payload
      };
    case 'SET_TA_PASSENGER_TYPES':
      return {
        ...state,
        taPassengerTypes: action.payload
      };
    case 'SET_USER_PASSENGER_TYPE':
      return {
        ...state,
        userPassengerType: action.payload
      };
    case 'SET_TRANSIT_AGENCY_FEATURES':
      return {
        ...state,
        transitAgencyFeatures: action.payload
      };
    default:
      return state;
  }
};
