import axios from 'axios';
import config from '../config';

export const getClientSecret = async ({
  passenger_id,
  transit_agency_id,
  email,
  first_name,
  last_name,
  phone_number,
  accessToken
}) => {
  const clientSecret = (
    await axios.post(
      `${config.blaiseApiUrl}/stripe/setupIntent`,
      {
        passenger_id,
        transit_agency_id,
        email,
        first_name,
        last_name,
        phone_number
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    )
  ).data?.client_secret;
  return clientSecret;
};
