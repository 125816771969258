import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loading.scss';

const Loading = () => (
  <div className="container">
    <CircularProgress className="logo" size={80} />
  </div>
);

export default Loading;
