import { subMinutes } from 'date-fns';

//HELPER METHODS FOR EVERYTHING RELATING TO TIME
//ex: timezone conversion, and formatting
//This class is SUPER important. The timezone needs to be transformed on the frontend to be in the timezone of the passengers phone
class Time {
  constructor() {
    this.formatAndTimezone = this.formatAndTimezone.bind(this);
    this.formatTime = this.formatTime.bind(this);
    this.changeOpZone = this.changeOpZone.bind(this);
    this.getToday = this.getToday.bind(this);
    this.getDisplayDate = this.getDisplayDate.bind(this);
    this.convertTimeToMinutes = this.convertTimeToMinutes.bind(this);
  }

  //Method to format the time correctly, as well as to change it to the users timezone.
  //This is a method to transform from GMT to current user TZ
  static formatAndTimezone(d) {
    const now = new Date();
    d = new Date(d);

    const temp = subMinutes(d, now.getTimezoneOffset());

    return temp.toISOString().slice(0, 10) + ' ' + temp.toISOString().slice(11, 19); //Not needed in FR: .slice(0, -3);
  }

  //Method to simply format the timezone. We need it in ISOString format to manipulate it easier and display what we want
  static formatTime(date) {
    return new Date(date).toISOString().substr(0, 10);
  }

  //Method to change the operating zone to GMT time
  static changeOpZone(date, time, offset) {
    let timeTemp = time.split(':');
    let temp = date.split('-');
    let dateTemp = new Date(temp[0], parseInt(temp[1]) - 1, temp[2], 0, 0, 0, 0);

    //Based on the offset, we either add it or remove it from the current time
    //Then, make sure the hours are still correct
    //ie, 12:122 -> 14:02
    if (offset > 1) {
      timeTemp[1] = parseInt(timeTemp[1]) + offset;
      timeTemp[0] = parseInt(timeTemp[0]) + Math.floor(timeTemp[1] / 60);
      timeTemp[1] = timeTemp[1] % 60;
    } else {
      timeTemp[1] = parseInt(timeTemp[1]) - offset;
      timeTemp[0] = parseInt(timeTemp[0]) - Math.floor(timeTemp[1] / 60);
      timeTemp[1] = timeTemp[1] % 60;
    }

    //method to add or remove a day if the time is >23:59 or <0:00
    if (timeTemp[0] / 24 >= 1) {
      timeTemp[0] = timeTemp[0] - 24;
      dateTemp.setMinutes(dateTemp.getMinutes() + 1440);
    } else if (timeTemp[0] < 0) {
      timeTemp[0] = timeTemp[0] + 24;
      dateTemp.setMinutes(dateTemp.getMinutes() - 1440);
    }

    //Return the date in the format accepted by the backend
    return {
      date:
        dateTemp.getFullYear().toString() +
        '-' +
        ('0' + (dateTemp.getMonth() + 1).toString()).slice(-2) +
        '-' +
        ('0' + dateTemp.getDate().toString()).slice(-2),
      time:
        ('0' + timeTemp[0].toString()).slice(-2) + ':' + ('0' + timeTemp[1].toString()).slice(-2)
    };
  }

  static getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  static getDisplayDate(config, ride) {
    if (config.isDemo) {
      return this.getToday();
    } else {
      return this.formatAndTimezone(ride);
    }
  }

  // the values to add to originalDatetime need to be passed as an object, values are 0 by default
  // returns in datetime-local format by default
  static addToDatetime(
    originalDatetime,
    { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0, datetimelocal = true }
  ) {
    originalDatetime.setSeconds(originalDatetime.getSeconds() + seconds);
    originalDatetime.setMinutes(originalDatetime.getMinutes() + minutes);
    originalDatetime.setHours(originalDatetime.getHours() + hours);
    originalDatetime.setDate(originalDatetime.getDate() + days);
    originalDatetime.setMonth(originalDatetime.getMonth() + months); // getMonth() returns an integer between 0 and 11
    originalDatetime.setYear(originalDatetime.getFullYear() + years);

    if (datetimelocal) {
      originalDatetime.setMinutes(
        originalDatetime.getMinutes() - originalDatetime.getTimezoneOffset()
      ); // maintaining timezone offset
      originalDatetime = originalDatetime.toISOString().slice(0, -8);
    }

    return originalDatetime;
  }

  // the values to subtract from originalDatetime need to be passed as an object, values are 0 by default
  // returns in datetime-local format by default
  static subtractFromDatetime(
    originalDatetime,
    { years = 0, months = 0, days = 0, hours = 0, minutes = 0, seconds = 0, datetimelocal = true }
  ) {
    originalDatetime.setSeconds(originalDatetime.getSeconds() - seconds);
    originalDatetime.setMinutes(originalDatetime.getMinutes() - minutes);
    originalDatetime.setHours(originalDatetime.getHours() - hours);
    originalDatetime.setDate(originalDatetime.getDate() - days);
    originalDatetime.setMonth(originalDatetime.getMonth() - months); // getMonth() returns an integer between 0 and 11
    originalDatetime.setYear(originalDatetime.getFullYear() - years);

    if (datetimelocal) {
      originalDatetime.setMinutes(
        originalDatetime.getMinutes() - originalDatetime.getTimezoneOffset()
      ); // maintaining timezone offset
      originalDatetime = originalDatetime.toISOString().slice(0, -8);
    }
    return originalDatetime;
  }

  // Method that converts date object to datetime-local string
  static getDatetimeLocal(dateObject) {
    dateObject.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
    const datetimeString = dateObject.toISOString().slice(0, -8);
    return datetimeString;
  }

  // Method that converts datetime-local string to date object
  static getDateObject(datetimeString) {
    return new Date(
      datetimeString.split('-')[0],
      datetimeString.split('-')[1] - 1,
      datetimeString.split('-')[2].split('T')[0],
      datetimeString.split('T')[1].split(':')[0],
      datetimeString.split('T')[1].split(':')[1]
    );
  }

  /**
   * Converts a time string of format hh:mm or hh:mm:ss to an integer representing the time in minutes
   * The seconds part is ignored in the calculation
   * @param {*} time Time string hh:mm or hh:mm:ss
   */
  static convertTimeToMinutes(time) {
    time = time.split(':');
    return +time[0] * 60 + +time[1];
  }
}

export default Time;
