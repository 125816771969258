import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCreditCard,
  faDollarSign,
  faSimCard
} from '@fortawesome/free-solid-svg-icons';
import BlaiseLogoSmallImg from '../../assets/images/blaise_logo_b_small.png';
import colors from '../../assets/sass/colors';
import moment from 'moment';
import { PaymentType } from '../../enums/PaymentType';
import { creditCardDateFormat } from '../../utils/dateTimeFormats';
import { useDispatch } from 'react-redux';

// For all payment method types except pass
const PaymentMethodItem = ({
  paymentItem,
  isSelected,
  text,
  cardDetails,
  handleDetachCard,
  detachText,
  viewFromTripRequest,
  viewFromPurchasePassModal,
  showExpiryDate = false
}) => {
  const [viewDetachCard, setViewDetachCard] = useState(false);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const type = paymentItem.payment_type;
  const id = '';

  const selectedItem = type === PaymentType.CARD ? cardDetails : { id, type, translation: text };

  const getPaymentIcon = () => {
    switch (type) {
      case `${PaymentType.CARD}`:
      case `${PaymentType.ONE_TIME_PAYMENT}`:
        return (
          <FontAwesomeIcon style={styles.icon} icon={faCreditCard} color={colors.black} size="2x" />
        );
      case `${PaymentType.TICKETS}`:
        return (
          <img
            style={styles.icon}
            alt={t('altText.ticket_payment_icon')}
            src={BlaiseLogoSmallImg}
          />
        );
      case `${PaymentType.SMART_CARD}`:
        return (
          <FontAwesomeIcon style={styles.icon} icon={faSimCard} color={colors.black} size="2x" />
        );
      default:
        return (
          <FontAwesomeIcon
            style={styles.icon}
            icon={faDollarSign}
            color={colors.gray800}
            size="lg"
          />
        );
    }
  };

  const isCardExpiryDateValid = (cardMonth, cardYear) => {
    // Months with Moment are 0 indexed, so +1 for corrent month.
    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();

    if (currentYear >= cardYear && currentMonth > cardMonth) {
      return false;
    }

    return true;
  };

  let containerStyle = styles.pageViewItemContainer;
  if (viewFromTripRequest) {
    containerStyle = styles.tripRequestItemContainer;
  } else if (viewFromPurchasePassModal) {
    containerStyle = styles.purchasePassModalItemContainer;
  }
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Button
          onClick={() => dispatch({ type: 'SET_PAYMENT_METHOD', payload: selectedItem })}
          style={containerStyle}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="row" alignItems="center">
              {getPaymentIcon()}
              <Box style={styles.title}>
                <Grid>{text}</Grid>
                {/* Card has not expired */}
                {showExpiryDate &&
                  cardDetails &&
                  isCardExpiryDateValid(cardDetails.card.exp_month, cardDetails.card.exp_year) && (
                    <Box style={{ color: colors.blaiseGray, fontSize: '14px' }}>
                      {t('payment.expires_on_x', {
                        expiryDate: creditCardDateFormat(
                          cardDetails.card.exp_month,
                          cardDetails.card.exp_year
                        )
                      })}
                    </Box>
                  )}

                {/* Card has expired */}
                {showExpiryDate &&
                  cardDetails &&
                  !isCardExpiryDateValid(cardDetails.card.exp_month, cardDetails.card.exp_year) && (
                    <Box style={{ color: colors.blaiseRed, fontSize: '14px', width: '250px' }}>
                      {t('payment.expired_on_x', {
                        expiryDate: creditCardDateFormat(
                          cardDetails.card.exp_month,
                          cardDetails.card.exp_year
                        )
                      })}
                    </Box>
                  )}
              </Box>
            </Box>

            <Box style={{ width: '20px' }}>
              {isSelected && (
                <FontAwesomeIcon
                  icon={faCheck}
                  color={colors.blaiseGreen}
                  size="lg"
                  aria-hidden="true"
                  title={`${text} selected`}
                />
              )}
            </Box>
          </Grid>
        </Button>
        {type === PaymentType.CARD && handleDetachCard && (
          <IconButton
            style={styles.viewButton}
            onClick={() => setViewDetachCard(!viewDetachCard)}
            aria-label={t('payment.toggle_expand_item')}
          >
            {viewDetachCard ? (
              <FontAwesomeIcon icon={faAngleUp} color={colors.black} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} color={colors.black} size="lg" />
            )}
          </IconButton>
        )}
      </Box>
      {viewDetachCard && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            style={styles.detachButton}
            onClick={() => handleDetachCard(cardDetails.id)}
          >
            {detachText}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PaymentMethodItem;

const styles = {
  icon: {
    width: 20
  },
  pageViewItemContainer: {
    padding: '0.5em',
    margin: '0.5em',
    width: '100%'
  },
  purchasePassModalItemContainer: {
    padding: '0.5em',
    margin: '0.5em',
    width: '100%',
    boxShadow: `1px 1px 1px 1px ${colors.gray300}`
  },
  tripRequestItemContainer: {
    width: '100%',
    borderBottom: `1px solid ${colors.gray300}`,
    padding: '1rem 1.25rem'
  },
  detachButton: {
    marginRight: '1em'
  },
  title: {
    paddingLeft: 16,
    paddingRight: 30,
    textAlign: 'left'
  },
  viewButton: {
    backgroundColor: 'transparent'
  }
};
