import React, { useEffect, useState } from 'react';
import './MinimizedSidebar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';
import {
  faClock,
  faRoute,
  faBus,
  faWallet,
  faUser,
  faBars,
  faSignOutAlt,
  faMobileAlt
} from '@fortawesome/free-solid-svg-icons';
import { Box, Avatar, Button, CircularProgress } from '@material-ui/core';
import SideBarItem from './SidebarItem';
import ROUTES from '../../utils/routes';
import { Link } from 'react-router-dom';
import { MountAnimation } from '../MountAnimation/MountAnimation';
import config from '../../config';
import { useTranslation } from 'react-i18next';

const ICON_COLOR = colors.gray500;

const MinimizedSidebar = ({
  handleCollapse,
  handleLogout,
  handleExpandSidebarOperatorSelection,
  handleExpandAccordion,
  helpRef,
  aboutUsRef,
  isLoadingOperatorList,
  selectedOperator,
  operatorList,
  setSidebarExpanded,
  operatorSelected
}) => {
  const [onMountAnimation, setOnMountAnimation] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    setOnMountAnimation(true);
  }, []);

  return (
    <MountAnimation isVisible={onMountAnimation}>
      <Box className="minimized-sidebar-container">
        {/* Upper icons */}
        <Box className="icon-group">
          <Button className="hamburger-collapse-button" onClick={handleCollapse}>
            <FontAwesomeIcon color={colors.black} icon={faBars} size="lg" />
          </Button>
          {operatorList && (
            <Avatar
              src={`${config.blaiseApiUrl}${selectedOperator?.logo_path}`}
              alt={`${selectedOperator?.transit_agency_name} logo`}
              style={{ height: '36px', width: '36px', cursor: 'pointer' }}
              onClick={handleExpandSidebarOperatorSelection}
            />
          )}
          {!operatorList && isLoadingOperatorList && (
            <Box style={{ height: '36px', width: '36px', cursor: 'pointer' }}>
              <CircularProgress aria-label="Progress Icon" aria-busy="true" />
            </Box>
          )}
          {!operatorList && !isLoadingOperatorList && (
            <Box
              style={{
                height: '36px',
                width: '36px',
                background: '#e9eaea',
                borderRadius: '50%',
                cursor: 'pointer'
              }}
              onClick={() => setSidebarExpanded(true)}
            />
          )}
          <hr />
          <SideBarItem
            minimized={true}
            label={t('navbar.request_a_ride')}
            route={ROUTES.home}
            icon={
              <FontAwesomeIcon
                icon={faBus}
                color={ICON_COLOR}
                style={{ height: '24px', width: '24px' }}
                fixedWidth
              />
            }
          />
          <SideBarItem
            minimized={true}
            label={t('navbar.trips')}
            route={ROUTES.trips}
            icon={
              <FontAwesomeIcon
                icon={faRoute}
                color={ICON_COLOR}
                style={{ height: '24px', width: '24px' }}
                fixedWidth
              />
            }
          />
          <SideBarItem
            minimized={true}
            label={t('navbar.historicaltrips')}
            route={ROUTES.history}
            icon={
              <FontAwesomeIcon
                icon={faClock}
                color={ICON_COLOR}
                style={{ height: '24px', width: '24px' }}
                fixedWidth
              />
            }
          />
          <SideBarItem
            minimized={true}
            label={t('navbar.wallet')}
            route={ROUTES.wallet}
            icon={
              <FontAwesomeIcon
                icon={faWallet}
                color={ICON_COLOR}
                style={{ height: '24px', width: '24px' }}
                fixedWidth
              />
            }
          />
          {operatorSelected && (
            <SideBarItem
              minimized={true}
              label={t('navbar.profile')}
              route={ROUTES.profile}
              icon={
                <FontAwesomeIcon
                  icon={faUser}
                  color={ICON_COLOR}
                  style={{ height: '24px', width: '24px' }}
                  fixedWidth
                />
              }
            />
          )}
        </Box>
        {/* Middle icons */}
        <Box className="icon-group">
          <Button className="logout-button-minimized" onClick={() => handleLogout()}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={ICON_COLOR}
              style={{ width: '24px', height: '24px' }}
            />
          </Button>
          <hr />
          <Button
            onClick={() => handleExpandAccordion(helpRef)}
            style={{ fontSize: '14px', margin: 0, padding: '4px 3px' }}
          >
            {t('navbar.help')}
          </Button>
          <Button
            onClick={() => handleExpandAccordion(aboutUsRef)}
            style={{ fontSize: '14px', margin: 0, padding: '4px 3px' }}
          >
            {t('navbar.about')}
          </Button>
        </Box>

        {/* Bottom icons */}
        <Box className="icon-group">
          <Link to={ROUTES.downloadTheApp} style={{ textDecoration: 'none' }}>
            <Box className="download-the-app-wrapper">
              <Box className="app-icon-wrapper">
                <FontAwesomeIcon icon={faMobileAlt} color={colors.blaiseGreen} size="2x" />
              </Box>
            </Box>
          </Link>
        </Box>
      </Box>
    </MountAnimation>
  );
};

export default MinimizedSidebar;
