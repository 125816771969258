import { format } from 'date-fns';
import { enUS, frCA, es } from 'date-fns/locale';

const LANGUAGE = navigator.language.substring(0, 2);

export const DateTimePickerFormatting = {
  en: 'MMMM Do, h:mma',
  fr: 'MMMM Do, HH:mm'
};

// TODO spanish trans
const locale = {
  en: enUS,
  es: es,
  fr: frCA
};

const dateFormats = {
  en: 'MMMM d, yyyy',
  es: 'd MMMM, yyyy',
  fr: 'd MMMM, yyyy'
};
const dateTimeFormats = {
  en: 'MMMM d, yyyy - HH:mm',
  es: 'd MMMM yyyy - HH:mm',
  fr: 'd MMMM yyyy - HH:mm'
};

export const dateFormat = (dateObject) => {
  const currentLocale = locale[LANGUAGE];
  const currentFormat = dateFormats[LANGUAGE];
  return format(dateObject, currentFormat, { locale: currentLocale });
};

/**
 * Consistent datetime format
 * @param {Date} dateObject to format
 *
 * @returns {string} formatted string
 */
export const datetimeFormat = (dateObject) => {
  const currentLocale = locale[LANGUAGE];
  const currentFormat = dateTimeFormats[LANGUAGE];
  return format(dateObject, currentFormat, { locale: currentLocale });
};

export const creditCardDateFormat = (month, year) => {
  const formattedMonth =
    month.toString().length === 2 ? month.toString() : month.toString().padStart(2, '0');
  const formattedYear = year.toString().slice(-2);

  return `${formattedMonth}/${formattedYear}`;
};
