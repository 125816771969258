import React, { useState } from 'react';
import { useQRCode } from 'next-qrcode';
import { Button, Box, Tabs, Tab, Typography, Modal, Paper, IconButton } from '@material-ui/core';
import { BrowserView, MobileView, isIOS, isMobile } from 'react-device-detect';
import AppStoreImg from '../../assets/images/app-store-badge.png';
import GooglePlayImg from '../../assets/images/google-play-badge.png';
import colors from '../../assets/sass/colors';
import { useTranslation } from 'react-i18next';
import './DownloadTheAppQRCode.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DownloadTheAppQRCode = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const { Canvas } = useQRCode();
  const { t } = useTranslation('common');

  const switchTabPanel = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const qrCodeOptions = {
    type: 'image/jpeg',
    quality: 0.3,
    level: 'M',
    margin: 3,
    scale: 4,
    width: 150,
    color: {
      dark: colors.gray700,
      light: colors.white
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Modal open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
        <Paper elevation={0} className="qr-code-instructions-modal">
          <IconButton
            onClick={() => setIsHelpModalOpen(false)}
            className="close-popup"
            aria-label={t('qrCode.close_popup')}
          >
            <FontAwesomeIcon icon={faTimes} color={colors.gray400} size="lg" />
          </IconButton>
          <Typography
            style={{ textAlign: 'center' }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {t('qrCode.how_do_i_do_this')}
          </Typography>
          <Box display="flex" alignItems="center">
            <ol>
              <li>{t('qrCode.open_camera')}</li>
              <li>{t('qrCode.point_your_phone')}</li>
              <li>{t('qrCode.popup_will_appear')}</li>
              <li>{t('qrCode.tap_the_notification')}</li>
            </ol>
            <Box>
              <Box>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  onChange={switchTabPanel}
                  aria-label="Smart phone operating system"
                >
                  <Tab style={{ textTransform: 'initial' }} label="iPhone" />
                  <Tab style={{ textTransform: 'initial' }} label="Android" />
                </Tabs>
              </Box>
              <TabPanel selectedTab={selectedTab} index={0}>
                <Canvas
                  text={'https://apps.apple.com/ca/app/blaise-transit/id1502727307'}
                  options={qrCodeOptions}
                />
              </TabPanel>
              <TabPanel selectedTab={selectedTab} index={1}>
                <Canvas
                  text={'https://play.google.com/store/apps/details?id=com.passengerappnative'}
                  options={qrCodeOptions}
                />
              </TabPanel>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => setIsHelpModalOpen(false)}
              variant="contained"
              style={{ color: colors.white, backgroundColor: colors.blaiseGreen }}
            >
              {t('qrCode.ok')}
            </Button>
          </Box>
        </Paper>
      </Modal>
      <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
        {t('qrCode.download_the_app')}
      </Typography>
      <Typography variant="subtitle1">{t('qrCode.for_the_best_experience')}</Typography>
      <BrowserView>
        <Typography variant="subtitle1" style={{ display: 'flex', alignItems: 'center' }}>
          {t('qrCode.scan_qr_code_below')}{' '}
          <Button onClick={() => setIsHelpModalOpen(true)} color="primary">
            {t('qrCode.how_do_i_do_this')}
          </Button>
        </Typography>
      </BrowserView>
      <Box
        display="flex"
        style={{
          gap: `${isMobile ? '0' : '50px'}`,
          flexDirection: `${isMobile ? 'column' : 'row'}`
        }}
      >
        {/* Value propositions section */}
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
          <ul>
            <li>{t('qrCode.free_download')}</li>
            <li>{t('qrCode.book_trips_from_anywhere')}</li>
            <li>{t('qrCode.receive_updates')}</li>
            <li>{t('qrCode.alternate_trip_suggestions')}</li>
            <li>{t('qrCode.and_more')}</li>
          </ul>
        </Box>
        {/* QR code section */}
        <BrowserView>
          <Box>
            <Box>
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                onChange={switchTabPanel}
                aria-label="Smart phone operating system"
              >
                <Tab style={{ textTransform: 'initial' }} label="iPhone" />
                <Tab style={{ textTransform: 'initial' }} label="Android" />
              </Tabs>
            </Box>
            <TabPanel selectedTab={selectedTab} index={0}>
              <Canvas
                text={'https://apps.apple.com/ca/app/blaise-transit/id1502727307'}
                options={qrCodeOptions}
              />
            </TabPanel>
            <TabPanel selectedTab={selectedTab} index={1}>
              <Canvas
                text={'https://play.google.com/store/apps/details?id=com.passengerappnative'}
                options={qrCodeOptions}
              />
            </TabPanel>
          </Box>
        </BrowserView>
        <MobileView>
          {isIOS ? (
            <a
              href={`https://apps.apple.com/ca/app/blaise-transit/id1502727307`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img style={{ width: '240px' }} src={AppStoreImg} alt="App Store badge" />
            </a>
          ) : (
            <a
              href={`https://play.google.com/store/apps/details?id=com.passengerappnative`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img style={{ width: '240px' }} src={GooglePlayImg} alt="Play Store badge" />
            </a>
          )}
        </MobileView>
      </Box>
    </Box>
  );
};

export default DownloadTheAppQRCode;

function TabPanel({ children, selectedTab, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTab === index && (
        <Box display="flex" justifyContent="center">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
