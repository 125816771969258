import React, { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Drawer, Button, Box } from '@material-ui/core';
import PassengerProfile from '../pages/PassengerProfile/PassengerProfile';
import TripRequest from './TripRequest/TripRequest';
import TripDetails from '../pages/TripDetails/TripDetails';
import ViewRide from '../pages/ViewRide/ViewRide';
import TripRating from '../pages/TripRating/TripRating';
import TripProcess from '../pages/TripProcess/TripProcess';
import HomeMap from '../pages/HomeMap/HomeMap';
import HistoricalRides from '../pages/HistoricalRides/HistoricalRides';
import DownloadTheApp from '../pages/DownloadTheApp/DownloadTheApp';
import ViewWallet from '../pages/Wallet/ViewWallet';
import Loading from './Loading/Loading';
import config from '../config';
import * as Sentry from '@sentry/react';
import { initFirebaseAppDB } from '../utils/MessagingService';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../assets/sass/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar/Sidebar';
import ROUTES from '../utils/routes';
import colors from '../assets/sass/colors';
import './App.scss';
import logoB from '../assets/images/blaise_logo_b.png';
import axios from 'axios';
import { isDesktop, isMobile } from 'react-device-detect';
import StripeWrapper from '../components/Stripe/StripeWrapper';
import CustomSnackbar from './Snackbar/CustomSnackbar';
import { checkIfBlaiseEmployeeOrUATUser } from '../auth/helpers';
import NoTransitAgencyFound from '../pages/NoTransitAgencyFound/NoTransitAgencyFound';

export const AccessTokenContext = createContext(); // Using a context because accessToken needs to be app wide but not persist.
export const GeoJSONContext = createContext(); // Using a context because geojson needs to be app wide but not persist.

const App = () => {
  const [appIsReady, setAppIsReady] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState(null);
  const [hasCheckedConnectedStripeAccount, setHasCheckedConnectedStripeAccount] = useState(false);
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
  const [operatorList, setOperatorList] = useState(null);
  const [activeOperatorList, setActiveOperatorList] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [isLoadingOperatorList, setIsLoadingOperatorList] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [geoJSON, setGeoJSON] = useState(null);
  const [noTransitAgencyFound, setNoTransitAgencyFound] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, user, isLoading } =
    useAuth0();
  const { t, i18n } = useTranslation('common');
  const dispatch = useDispatch();
  const selectedTransitAgency = useSelector((state) => state.operatorSelected);
  const originAddress = useSelector((state) => state.originAddress);
  const originCoords = useSelector((state) => state.originCoords);
  const currentLocation = useSelector((state) => state.currentLocation);
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);
  const userPassengerType = useSelector((state) => state.userPassengerType);

  useEffect(() => {
    // TODO: Figure out culprit which makes this necessary.
    // May need to lift currentLocation and mapCentre logic to App level and then incorporate into appIsReady.
    // Backwards compatibility for the rewrite.
    const browserStorage = JSON.parse(localStorage.getItem('state'));
    const reloadedOnceAlready = sessionStorage.getItem('reloadedOnceAlready');
    setTimeout(() => {
      if (!reloadedOnceAlready && !browserStorage?.mapCentre) {
        localStorage.removeItem('state');
        sessionStorage.setItem('reloadedOnceAlready', true);

        location.reload();
      }
    }, 3000);

    (async () => {
      await initFirebaseAppDB();
    })();

    // Get passenger's currentLocation if not already saved in localStorage.
    if (!originCoords) {
      getUserLocation();
    }

    // Wipe previous TA info.
    refreshOperatorList();
  }, []);

  useEffect(() => {
    if (selectedOperator) {
      dispatch({ type: 'SET_OPERATOR_SELECTED', payload: selectedOperator });
    }
  }, [selectedOperator]);

  useEffect(() => {
    // Ensure there passengerTypes and user passengerTypes are set
    if ((!taPassengerTypes || !userPassengerType) && selectedTransitAgency && user) {
      getPassengerTypes(selectedTransitAgency);
    }
  }, [selectedTransitAgency, user]);

  useEffect(() => {
    if (
      operatorList === null &&
      activeOperatorList === null &&
      user &&
      currentLocation &&
      accessToken
    ) {
      fetchOperatorList();
    }
  }, [operatorList, activeOperatorList, user, currentLocation, accessToken]);

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently();

        setAccessToken(token);
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // If previously selected operator, get from info from store
    if (accessToken && selectedTransitAgency) {
      setSelectedOperator(selectedTransitAgency);
      setOperator(selectedTransitAgency);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && selectedTransitAgency?.transit_agency_id) {
      getConnectedStripeAccount();
    }
  }, [accessToken, selectedTransitAgency]);

  useEffect(() => {
    // Backwards compatibility for when we changed lat/long from array to object.
    if (Array.isArray(originCoords)) {
      dispatch({
        type: 'SET_ORIGIN_COORDS',
        payload: {
          lat: originCoords[0],
          lng: originCoords[1]
        }
      });
    }
  }, [originCoords]);

  useEffect(() => {
    // Auth0 user object has loaded, originCoords is not an array, access token is stored.
    if (user && !Array.isArray(originCoords) && accessToken) {
      setAppIsReady(true);
    }
  }, [originCoords, user, accessToken]);

  // if there is an operator selected, get link from redux
  let operatorSupportLink;
  if (selectedTransitAgency && i18n.language.substring(0, 2) === 'en') {
    operatorSupportLink = selectedTransitAgency.support_en;
  }

  if (selectedTransitAgency && i18n.language.substring(0, 2) === 'fr') {
    operatorSupportLink = selectedTransitAgency.support_fr;
  }

  const getConnectedStripeAccount = async () => {
    try {
      const transitAgencyResponse = (
        await axios.get(
          `${config.blaiseApiUrl}/transitagencies/${selectedTransitAgency.transit_agency_id}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      // stripe_account will only be in the response if the TA has connected account.
      if (transitAgencyResponse?.stripe_account) {
        setStripeAccountId(transitAgencyResponse.stripe_account);
      }

      setHasCheckedConnectedStripeAccount(true);
    } catch (error) {
      console.log('[App::getConnectedStripeAccount] ', error);
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const getUserLocation = () => {
    const successCallback = async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      dispatch({ type: 'SET_CURRENT_LOCATION', payload: `${lat},${lng}` });

      // If there's no operator selected, show current location
      if (!selectedTransitAgency) {
        dispatch({ type: 'SET_MAP_CENTRE', payload: { lat, lng } });
      }
    };

    const errorCallback = (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        openSnackbar(t('console.locationAccess', colors.red));
      } else {
        openSnackbar(t('console.genericError'), colors.red);
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      openSnackbar(t('console.genericError'), colors.red);
    }
  };

  const fetchOperatorList = async () => {
    setIsLoadingOperatorList(true);
    try {
      if (!currentLocation) throw new Error('Location is not set.');

      const location = currentLocation.split(',');
      // use to use actual location
      const reqBody = {
        lat: Number(location[0]),
        lon: Number(location[1]),
        passengerId: user.sub
      };
      const nearbyOperators = (
        await axios.post(`${config.blaiseApiUrl}/transitagencies/nearby`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      const nearbyActiveOperators = nearbyOperators.filter((item) => {
        if (
          item.is_active === 1 ||
          (item.is_active === 3 && checkIfBlaiseEmployeeOrUATUser(user))
        ) {
          return item;
        }
      });

      setOperatorList(nearbyOperators);
      setActiveOperatorList(nearbyActiveOperators);

      if (nearbyActiveOperators.length === 0) {
        setNoTransitAgencyFound(true);
      }

      if (nearbyActiveOperators.length > 0 && !selectedTransitAgency) {
        await axios.put(
          `${config.blaiseApiUrl}/passengers/${user.sub}/linkPBalanceToTA/${nearbyActiveOperators[0].transit_agency_id}`,
          {},
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        await axios.put(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${nearbyActiveOperators[0].transit_agency_id}/linkPassengerToTA`,
          {},
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );

        const rosemont = nearbyActiveOperators.find(
          (transitAgency) => transitAgency.transit_agency_name === 'Rosemont'
        );

        // Automatically selects Rosemont as your TA, if you are a Blaise employee.
        setSelectedOperator(
          (checkIfBlaiseEmployeeOrUATUser(user) && rosemont) || nearbyActiveOperators[0]
        );
        setOperator(nearbyActiveOperators[0]);
      }
    } catch (error) {
      console.log('[OperatorSelection::fetchOperatorList] ', error);
      setOperatorList(false);
    }
    setIsLoadingOperatorList(false);
  };

  const setOperator = async (ta, isFromManuallySelectingNewOperator = false) => {
    try {
      dispatch({ type: 'SET_OPERATOR_SELECTED', payload: ta });

      const stops = await axios.get(`${config.blaiseApiUrl}/busstops/${ta.transit_agency_id}`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      const stopCollection = {
        center: [-73.567531, 45.506595], // Cinema Imperial coords (demo day location :P)
        busStops: []
      };

      stops.data.busStops.map((busStop) => {
        if (busStop.is_depot !== 1) {
          stopCollection.busStops.push({
            id: busStop.bus_stop_id,
            coord: [parseFloat(busStop.lon), parseFloat(busStop.lat)]
          });
        }
      });

      const geoJSON = await getGeoJSON(ta.transit_agency_id);
      setGeoJSON(geoJSON);

      const transitAgencyFeatures = await getTransitAgencyFeatures(ta.transit_agency_id);
      if (transitAgencyFeatures.length > 0) {
        dispatch({ type: 'SET_TRANSIT_AGENCY_FEATURES', payload: transitAgencyFeatures });
      }

      dispatch({ type: 'SET_BUS_STOPS_FOR_TA', payload: stopCollection });
      dispatch({ type: 'SET_OPERATOR_SELECTED', payload: ta });
      if (!originAddress) {
        dispatch({
          type: 'SET_MAP_CENTRE',
          payload: {
            lat: Number(ta.center_lat),
            lng: Number(ta.center_lon)
          }
        });
      }

      // Reload passenger types when TA changes
      await getPassengerTypes(ta);

      if (isFromManuallySelectingNewOperator) {
        // Required when changing operator, as StripeWrapper needs to be reinitialized with new TA.
        location.reload();
      }
    } catch (err) {
      console.log('App::setOperator', err);
    }
  };

  const getGeoJSON = async (taid) => {
    try {
      const getGeojson = await axios.get(`${config.blaiseApiUrl}/transitagencies/${taid}/geojson`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      return getGeojson.data.zone_geojson === null ? null : getGeojson.data.zone_geojson;
    } catch (err) {
      console.log('App::getGeoJSON', err);
    }
  };

  const getPassengerTypes = async (ta) => {
    const taPassengerTypesPromise = axios.get(
      `${config.blaiseApiUrl}/transitagencies/${ta.transit_agency_id}/passengerTypes`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    const userPassengerTypePromise = axios.get(
      `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${ta.transit_agency_id}/passengerTypeVerification`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    await Promise.all([taPassengerTypesPromise, userPassengerTypePromise])
      .then(([taPassengerTypes, userPassengerType]) => {
        dispatch({ type: 'SET_TA_PASSENGER_TYPES', payload: taPassengerTypes.data });
        dispatch({ type: 'SET_USER_PASSENGER_TYPE', payload: userPassengerType.data });
      })
      .catch((error) => {
        console.log('App::getPassengerTypes promise all ', error);
      });
  };

  const getTransitAgencyFeatures = async (transitAgencyId) => {
    try {
      const features = await axios.get(
        `${config.blaiseApiUrl}/features?transitAgencyId=${transitAgencyId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      return features.data;
    } catch (err) {
      console.log('App::getTransitAgencyFeatures', err);
    }
  };
  /**
   * Refreshes the operator list. Setting status to null to show loading icon.
   */
  const refreshOperatorList = () => {
    setOperatorList(null);
    setActiveOperatorList(null);
  };

  const appStyles = {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flex: '1 1 0%'
  };

  if (!isLoading && !isAuthenticated) {
    loginWithRedirect();
  } else if (noTransitAgencyFound) {
    return <NoTransitAgencyFound setAccessToken={setAccessToken} />;
  } else if (isLoading || !hasCheckedConnectedStripeAccount) {
    return <Loading />;
  } else if (isAuthenticated) {
    const stripeAccount = stripeAccountId ?? '';

    return (
      <AccessTokenContext.Provider value={accessToken}>
        <GeoJSONContext.Provider value={geoJSON}>
          <StripeWrapper stripeAccount={stripeAccount}>
            <ThemeProvider theme={theme}>
              <CustomSnackbar
                open={snackbar.open}
                message={snackbar.message}
                snackbarColor={snackbar.color}
                onClose={closeSnackbar}
              />
              {appIsReady && (
                <div style={appStyles}>
                  {isDesktop && (
                    // Desktop sidebar
                    <Sidebar
                      operatorSupportLink={operatorSupportLink}
                      isLoadingOperatorList={isLoadingOperatorList}
                      operatorList={operatorList}
                      selectedOperator={selectedOperator}
                      setOperator={setOperator}
                      setSelectedOperator={setSelectedOperator}
                      setAccessToken={setAccessToken}
                    />
                  )}

                  {/* Ensures user info is available before any pages load */}
                  <main style={{ width: '100%', overflowX: 'hidden' }}>
                    {/* Mobile & tablet top nav + sidebar */}
                    {isMobile && (
                      <>
                        <Drawer
                          anchor="right"
                          open={isMobileDrawerOpen}
                          onClose={() => setIsMobileDrawerOpen(false)}
                          style={{ width: '100%' }}
                          className="mobile-sidebar-drawer"
                        >
                          <Sidebar
                            operatorSupportLink={operatorSupportLink}
                            setIsMobileDrawerOpen={setIsMobileDrawerOpen}
                            isLoadingOperatorList={isLoadingOperatorList}
                            operatorList={operatorList}
                            selectedOperator={selectedOperator}
                            setOperator={setOperator}
                            setSelectedOperator={setSelectedOperator}
                            setAccessToken={setAccessToken}
                          />
                        </Drawer>
                        <Box className="mobile-topnav">
                          <Box className="logoWrapper">
                            <img className="logo" src={logoB} alt="Blaise Transit" />
                            <h1 className="companyName">blaise</h1>
                          </Box>
                          <Button
                            onClick={() => setIsMobileDrawerOpen(true)}
                            className="hamburger-collapse-button"
                          >
                            <FontAwesomeIcon color={colors.black} icon={faBars} size="lg" />
                          </Button>
                        </Box>
                      </>
                    )}
                    <Switch>
                      <Route exact={true} path={ROUTES.home} component={HomeMap} />
                      <Route path={ROUTES.profile} component={PassengerProfile} />
                      <Route path={ROUTES.tripRequest} component={TripRequest} />
                      <Route path={ROUTES.trips} component={ViewRide} />
                      <Route path={ROUTES.history} component={HistoricalRides} />
                      <Route path={ROUTES.tripDetails} component={TripDetails} />
                      <Route path={ROUTES.tripRating} component={TripRating} />
                      <Route path={ROUTES.tripProcess} component={TripProcess} />
                      <Route path={ROUTES.downloadTheApp} component={DownloadTheApp} />
                      <Route path={ROUTES.wallet} component={ViewWallet} />
                    </Switch>
                  </main>
                </div>
              )}

              {!appIsReady && (
                <div className="blaise-progress-top centered">
                  <CircularProgress aria-label="Progress Icon" aria-busy="true" />
                </div>
              )}
            </ThemeProvider>
          </StripeWrapper>
        </GeoJSONContext.Provider>
      </AccessTokenContext.Provider>
    );
  } else {
    // Should not happen
    console.error('There was an issue with the if/else in App.js');
    return <h1>{t('console.genericError')}</h1>;
  }
};

export default withRouter(Sentry.withProfiler(App));
