import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import { faTimes, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../assets/sass/colors';
import './AddPassenger.scss';
import { PassengerAndPaymentView } from '../../../enums/passengerAndPaymentViews';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isAndroid, isMobile, isTablet } from 'react-device-detect';

const LANGUAGE = navigator.language.substring(0, 2);

const AddPassenger = ({
  setCurrentPassengerAndPaymentView,
  accommodationTypes,
  translatePassengerType,
  openSnackbar
}) => {
  const [passengerList, setPassengerList] = useState([]); // Local state is used so that if the user closes the modal without saving, it won't save changes.
  const [expanded, setExpanded] = useState('panel-0');
  const passengers = useSelector((state) => state.passengers);
  const taPassengerTypes = useSelector((state) => state.taPassengerTypes);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  useEffect(() => {
    flattenSavedPassengers();
  }, []);

  const flattenSavedPassengers = () => {
    const formattedPassengers = Object.values(passengers).flat();

    setPassengerList(formattedPassengers);
  };

  const handleAccordionToggle = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangePassengerType = (passenger, index, newType) => {
    const updatedPassenger = { ...passenger, type: newType };
    const passengerListCopy = [...passengerList];

    passengerListCopy[index] = updatedPassenger;
    setPassengerList(passengerListCopy);
  };

  const toggleAccommodation = (passenger, index, accommodation) => {
    const accommodationIndex = passenger.accommodationTypes.findIndex(
      (element) => element.accomodation_id === accommodation.accomodation_id
    );
    const accommodationsCopy = [...passenger.accommodationTypes];
    accommodationsCopy[accommodationIndex] = {
      ...accommodation,
      selected: !accommodation.selected
    };
    const passengerListCopy = [...passengerList];
    passengerListCopy[index] = { ...passenger, accommodationTypes: accommodationsCopy };
    setPassengerList(passengerListCopy);
  };

  const addPassenger = () => {
    const newPassenger = {
      accommodationTypes,
      isExpanded: true,
      type: 'adult'
    };

    setPassengerList((prev) => [...prev, newPassenger]);
  };

  const removePassenger = (index) => {
    const passengerListCopy = [...passengerList];
    passengerListCopy.splice(index, 1);
    setPassengerList(passengerListCopy);

    openSnackbar(t('tripReq.removePassengerSuccess'), colors.blaiseGreen);
  };

  const handleLeaveView = async () => {
    const passengers = {};

    taPassengerTypes.map((passengerType) => {
      const passengersOfType = passengerList.filter(
        (passenger) => passenger.type === passengerType.type
      );
      passengers[passengerType.type] = passengersOfType;
    });

    dispatch({ type: 'SET_PASSENGERS', payload: passengers });
    setCurrentPassengerAndPaymentView(PassengerAndPaymentView.DEFAULT);
  };

  return (
    <Box className="add-passenger-container">
      {/* Header */}
      <Box className="header-container">
        <Typography className="header">{t('tripReq.passengers')}</Typography>
        <Button onClick={() => setCurrentPassengerAndPaymentView(PassengerAndPaymentView.DEFAULT)}>
          <FontAwesomeIcon
            color={colors.gray600}
            icon={faTimes}
            style={{ height: '16px', width: '16px' }}
          />
        </Button>
      </Box>

      <Box className="content">
        {/* Passengers */}
        <Box className="passengers-container">
          {passengerList.map((passenger, idx) => (
            <Accordion
              key={idx}
              className="accordion"
              expanded={expanded === `panel-${idx}`}
              onChange={handleAccordionToggle(`panel-${idx}`)}
              style={{ borderTop: `${idx === 0 && 'none'}` }}
            >
              <AccordionSummary
                className="accordion-title"
                expandIcon={<FontAwesomeIcon icon={faChevronDown} color={colors.black} size="xs" />}
              >
                <Typography>{translatePassengerType(passenger.type)}</Typography>
              </AccordionSummary>
              <Box className="accordion-content">
                <Box>
                  <Box className="section-title-container">
                    <Typography className="section-title">
                      {t('passenger.passenger_type')}
                    </Typography>
                  </Box>

                  {/* Passenger type */}
                  <Grid container>
                    {taPassengerTypes.map((passengerType) => (
                      <Grid item xs={4} key={passengerType.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="passenger-type-checkbox"
                              checked={passenger.type === passengerType.type}
                              onChange={() =>
                                handleChangePassengerType(passenger, idx, passengerType.type)
                              }
                              name="passenger-type"
                            />
                          }
                          label={translatePassengerType(passengerType.type)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                {/* Accommodations */}
                <Box className="section-title-container">
                  <Typography className="section-title">{t('passenger.accommodations')}</Typography>
                  <Typography className="section-subtitle">
                    {t('passenger.select_all_that_apply')}
                  </Typography>
                </Box>
                <Grid container spacing={1}>
                  {passenger.accommodationTypes.map((accommodation) => (
                    <Grid item xs={6} key={accommodation.accomodation_id}>
                      <Button
                        onClick={() => toggleAccommodation(passenger, idx, accommodation)}
                        className={`${accommodation.selected && 'selected'} accommodation`}
                      >
                        {LANGUAGE === 'en'
                          ? accommodation.translationName.english
                          : accommodation.translationName.french}
                      </Button>
                    </Grid>
                  ))}
                </Grid>

                <Button className="remove-passenger-button" onClick={() => removePassenger(idx)}>
                  {t('tripReq.remove_passenger')}
                </Button>
              </Box>
            </Accordion>
          ))}
        </Box>

        {/* Add passenger */}
        <Box className="add-passenger-button-container">
          <Button onClick={addPassenger}>
            <FontAwesomeIcon
              color={colors.gray600}
              icon={faPlus}
              style={{ height: '16px', width: '16px', marginRight: '1rem' }}
            />
            <Typography className="add-passenger-text">{t('passenger.add_passenger')}</Typography>
          </Button>
        </Box>
      </Box>

      <Box
        className="next-button"
        style={{
          paddingBottom: `${(isMobile || isTablet) && isAndroid && '76px'}` // Add 56px (onto the default 20) padding for Android's bottom nav
        }}
      >
        <Button color="primary" variant="contained" onClick={() => handleLeaveView()}>
          {t('profile.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddPassenger;
