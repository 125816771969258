import React from 'react';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import colors from '../../assets/sass/colors';
import TextField from '@material-ui/core/TextField';
import { CardElement } from '@stripe/react-stripe-js';
import './AddCardPaymentModal.scss';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Typography, IconButton } from '@material-ui/core';

const AddPaymentModal = (props) => {
  const { t } = useTranslation('common');

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883'
        },
        '::placeholder': {
          color: '#87bbfd'
        }
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="paper">
        <Box
          className="ticket-modal-title"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t('payment.add_payment_method')}</Typography>
          <IconButton onClick={() => props.handleClose(false)} aria-label="close">
            <FontAwesomeIcon
              icon={faTimes}
              style={{ cursor: 'pointer', color: colors.gray400, fontSize: '16px' }}
            />
          </IconButton>
        </Box>
        <Box display="flex" flexDirection="column">
          <TextField
            label={t('payment.full_name')}
            fullWidth={true}
            InputLabelProps={{ style: { color: colors.blaiseGreen, fontSize: 15 } }}
            onChange={(e) => {
              props.setBillingDetails({ ...props.billingDetails, name: e.target.value });
            }}
          />
          <TextField
            name="email"
            label={t('profile.email')}
            InputLabelProps={{ style: { color: colors.blaiseGreen, fontSize: 15 } }}
            onChange={(e) => {
              props.setBillingDetails({ ...props.billingDetails, email: e.target.value });
            }}
          />
          <TextField
            name="phonenumber"
            label={t('payment.phone_number')}
            InputLabelProps={{ style: { color: colors.blaiseGreen, fontSize: 15 } }}
            onChange={(e) => {
              props.setBillingDetails({ ...props.billingDetails, phone: e.target.value });
            }}
          />
        </Box>
        <div style={{ marginTop: 30, width: `${isDesktop && '350px'}` }}>
          <CardElement
            options={CARD_OPTIONS}
            onChange={(e) => {
              props.setError(e.error);
            }}
          />
        </div>
        <Box display="flex" justifyContent="center">
          <Button
            style={{ backgroundColor: colors.blaiseGreen, marginTop: '30px' }}
            onClick={props.savePayment}
          >
            {t('payment.save_payment')}
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default AddPaymentModal;
